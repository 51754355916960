import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

const AddCampaignForm = (props) => {
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const Agent = props.agent;

  // Fetch campaigns from the database
  const fetchCampaigns = async () => {
    try {
      const response = await fetch(
        "https://us-central1-argon-fx-383518.cloudfunctions.net/getAllCampaigns" // Firebase function to get campaigns
      );
      const data = await response.json();
      setCampaigns(data);
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
  };

  // Delete a campaign
  const deleteCampaign = async (adID) => {
    try {
      const response = await fetch(
        `https://us-central1-argon-fx-383518.cloudfunctions.net/DeleteCampaign`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ adID }),
        }
      );

      if (response.ok) {
        setCampaigns((prev) =>
          prev.filter((campaign) => campaign.AdID !== adID)
        );
        console.log("Campaign deleted successfully");
      } else {
        console.error("Error deleting campaign");
      }
    } catch (error) {
      console.error("Error deleting campaign:", error);
    }
  };

  useEffect(() => {
    if (Agent) {
      if (Agent.Hierarchy && Agent.Hierarchy.toLowerCase() === "master") {
        fetchCampaigns(); // Fetch campaigns on page load
      } else {
        navigate("/"); // Navigate if Agent is not master
      }
    } else {
      console.log("Waiting for Agent to be defined...");
    }
  }, [Agent, navigate]);

  const initialValues = {
    status: "Active",
    partnerID: "",
    partner: "",
    adType: "",
    platform: "",
    adID: "",
    image: "",
    description: "",
  };

  const validationSchema = yup.object().shape({
    status: yup.string().required("Required"),
    partnerID: yup.string().required("Required"),
    partner: yup.string().required("Required"),
    adType: yup.string().required("Required"),
    platform: yup.string().required("Required"),
    adID: yup.string().required("Required"),
    description: yup.string().required("Required"),
  });

  const handleSubmit = async (values) => {
    try {
      const response = await fetch(
        "https://us-central1-argon-fx-383518.cloudfunctions.net/AddNewCampaign", // Replace with your Firebase function URL
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );

      if (response.ok) {
        console.log("Campaign added successfully!");
        fetchCampaigns(); // Refresh campaign list after submission
        navigate("/campaigns");
      } else {
        console.error("Error adding campaign");
      }
    } catch (error) {
      console.error("Error submitting campaign:", error);
    }
  };

  return (
    <Box m={3}>
      <h2>Current Campaigns</h2>
      <List>
        {campaigns.map((campaign) => (
          <div key={campaign.AdID}>
            <ListItem
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => deleteCampaign(campaign.AdID)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText
                primary={`${campaign.AdID} (${campaign.Platform})`}
                secondary={campaign.Description}
              />
            </ListItem>
            <Divider />
          </div>
        ))}
      </List>

      <h2>Add New Campaign</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="20px" gridTemplateColumns="repeat(2, 1fr)">
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  name="status"
                  value={values.status}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!touched.status && !!errors.status}
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
              </FormControl>

              <TextField
                fullWidth
                label="Partner ID"
                name="partnerID"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.partnerID}
                error={!!touched.partnerID && !!errors.partnerID}
                helperText={touched.partnerID && errors.partnerID}
              />

              <TextField
                fullWidth
                label="Partner"
                name="partner"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.partner}
                error={!!touched.partner && !!errors.partner}
                helperText={touched.partner && errors.partner}
              />

              <TextField
                fullWidth
                label="Ad Type"
                name="adType"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.adType}
                error={!!touched.adType && !!errors.adType}
                helperText={touched.adType && errors.adType}
              />

              <TextField
                fullWidth
                label="Platform"
                name="platform"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.platform}
                error={!!touched.platform && !!errors.platform}
                helperText={touched.platform && errors.platform}
              />

              <TextField
                fullWidth
                label="Ad ID"
                name="adID"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.adID}
                error={!!touched.adID && !!errors.adID}
                helperText={touched.adID && errors.adID}
              />

              <TextField
                fullWidth
                label="Image URL"
                name="image"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.image}
                error={!!touched.image && !!errors.image}
                helperText={touched.image && errors.image}
              />

              <TextField
                fullWidth
                label="Description"
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
              />
            </Box>

            <Box mt={3} display="flex" justifyContent="center">
              <Button type="submit" color="primary" variant="contained">
                Add Campaign
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default AddCampaignForm;
