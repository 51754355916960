// File: src/components/FileUpload.js

import React, { useState } from "react";
import Papa from "papaparse";
import { Box, Button, Typography, useTheme } from "@mui/material";
import Header from "../components/Header";
import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState({
    "Entered Orders.csv": false,
    "Chargeback Pending.csv": false,
    "Retail Payout.csv": false,
  });

  const notify = (x) => toast(x);
  const notifyError = (x) => toast.warn(x);

  const handleFileChange = (event, fileType) => {
    const selectedFile = event.target.files[0];

    setFile(selectedFile);
  };

  const handleFileUpload = (fileType) => {
    if (!file) return;

    console.log(`Uploading file for ${fileType}:`, file);
    Papa.parse(file, {
      header: true,
      complete: async (result) => {
        const jsonData = result.data;
        console.log(`Parsed data for ${fileType}:`, jsonData);

        let functionUrl;
        switch (fileType) {
          case "Entered Orders.csv":
            functionUrl =
              "https://us-central1-argon-fx-383518.cloudfunctions.net/processEnteredOrders";
            break;
          case "Chargeback Pending.csv":
            functionUrl =
              "https://us-central1-argon-fx-383518.cloudfunctions.net/processChargebackPending";
            break;
          case "Retail Payout.csv":
            functionUrl =
              "https://us-central1-argon-fx-383518.cloudfunctions.net/processRetailPayout";
            break;
          default:
            console.error("Unknown file type");
            notifyError(`Incorrect file: ${fileType} Please Verify`);
            return;
        }

        console.log(`Sending data to ${functionUrl}`);

        try {
          const response = await fetch(functionUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(jsonData),
          });

          if (response.ok) {
            const data = await response.json();
            console.log(`Success response for ${fileType}:`, data.message);
            notify(`Successfully uploaded: ${fileType}`, data.message);
            setUploadStatus((prevStatus) => ({
              ...prevStatus,
              [fileType]: true,
            }));
          } else {
            const errorData = await response.json();
            console.error(`Error response for ${fileType}:`, errorData.error);
            notifyError(`Error uploading: ${fileType}, `, errorData.error);
          }
        } catch (error) {
          console.error(`Network error for ${fileType}:`, error);
          notifyError(`Network error for ${fileType}:`, error);
        }
      },
    });
  };

  const renderFileInput = (fileType) => (
    <Box mt={2} display="flex" flexDirection="column" alignItems="left">
      <input
        type="file"
        accept=".csv"
        onChange={(event) => handleFileChange(event, fileType)}
        style={{ display: "none" }}
        id={`file-upload-${fileType}`}
      />
      <label htmlFor={`file-upload-${fileType}`}>
        <Button variant="contained" component="span">
          Choose File
        </Button>
      </label>
      {file && (
        <Typography variant="body1" mt={2}>
          Selected file: {file.name}
        </Typography>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleFileUpload(fileType)}
        disabled={file?.name !== fileType || uploadStatus[fileType]}
        sx={{ mt: 2, maxWidth: "200px" }}
      >
        {uploadStatus[fileType] ? "File Uploaded" : "Upload File"}
      </Button>
    </Box>
  );

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="left">
        <Header title="Data Upload" />
      </Box>
      <Typography color={"black"} variant="h4" fontWeight="600" mt={4}>
        Entered Orders
      </Typography>
      {renderFileInput("Entered Orders.csv")}
      <Typography color={"black"} variant="h4" fontWeight="600" mt={4}>
        Chargeback Pending
      </Typography>
      {renderFileInput("Chargeback Pending.csv")}
      <Typography color={"black"} variant="h4" fontWeight="600" mt={4}>
        Retail Payout
      </Typography>
      {renderFileInput("Retail Payout.csv")}
    </Box>
  );
};

export default FileUpload;
