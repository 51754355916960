import { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import { mockPieData as data } from "../../data/mockData";

const PieChart = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const Agent = props.agent;
  const Data = props.data;
  const [mockPieData, setMockPieData] = useState([
    {
      id: "internet",
      label: "internet",
      value: 0,
      color: "hsl(104, 70%, 50%)",
    },
    {
      id: "video",
      label: "video",
      value: 0,
      color: "hsl(162, 70%, 50%)",
    },
    {
      id: "voice",
      label: "voice",
      value: 0,
      color: "hsl(291, 70%, 50%)",
    },
    {
      id: "mobile",
      label: "mobile",
      value: 0,
      color: "hsl(229, 70%, 50%)",
    },
  ]);

  useEffect(() => {
    if (Agent && Agent.PartnerID) {
      dataChurningMachine();
    }
  }, [Data]);

  const dataChurningMachine = () => {
    let iAssist = 0;
    let i50100 = 0;
    let i300 = 0;
    let iUltra = 0;
    let iGig = 0;

    Data.forEach((item) => {
      if (item.PSU == "Internet") {
        if (item.InternetTierTimeOfConnect == "SPP Internet Assist") {
          iAssist++;
        }
        if (item.InternetTierTimeOfConnect == "SPP Internet 50/100") {
          i50100++;
        }
        if (item.InternetTierTimeOfConnect == "SPP Spectrum Internet") {
          i300++;
        }
        if (item.InternetTierTimeOfConnect == "SPP Ultra") {
          iUltra++;
        }
        if (item.InternetTierTimeOfConnect == "SPP Gig") {
          iGig++;
        }
      }
    });

    const newMockPieData = [
      {
        id: "iAssist",
        label: "Assist",
        value: iAssist,
        color: "hsl(104, 70%, 50%)",
      },
      {
        id: "i50100",
        label: "50/100",
        value: i50100,
        color: "hsl(162, 70%, 50%)",
      },
      {
        id: "i300",
        label: "300",
        value: i300,
        color: "hsl(291, 70%, 50%)",
      },
      {
        id: "iUltra",
        label: "Ultra",
        value: iUltra,
        color: "hsl(229, 70%, 50%)",
      },
      {
        id: "iGig",
        label: "Gig",
        value: iGig,
        color: "hsl(229, 70%, 50%)",
      },
    ];

    setMockPieData(newMockPieData);
  };

  return (
    <ResponsivePie
      data={mockPieData}
      margin={{ top: 10, right: -30, bottom: 10, left: 0 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      enableArcLinkLabels={false}
      arcLinkLabelsSkipAngle={1}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={1}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: "ruby",
          },
          id: "dots",
        },
        {
          match: {
            id: "c",
          },
          id: "dots",
        },
        {
          match: {
            id: "go",
          },
          id: "dots",
        },
        {
          match: {
            id: "python",
          },
          id: "dots",
        },
        {
          match: {
            id: "scala",
          },
          id: "lines",
        },
        {
          match: {
            id: "lisp",
          },
          id: "lines",
        },
        {
          match: {
            id: "elixir",
          },
          id: "lines",
        },
        {
          match: {
            id: "javascript",
          },
          id: "lines",
        },
      ]}
      legends={[
        {
          anchor: "left",
          direction: "column",
          justify: false,
          translateX: 0,
          translateY: 0,
          itemWidth: 0,
          itemHeight: 20,
          itemsSpacing: 0,
          symbolSize: 20,
          itemDirection: "left-to-right",
        },
      ]}
    />
  );
};

export default PieChart;
