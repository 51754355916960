import React from "react";
import Iframe from "react-iframe";

const PartnerRegisterSale = () => {
  return (
    <div>
      <Iframe
        url="https://fs10.formsite.com/yU3au7/zfjlyterdr/index"
        width="100%"
        height="950px"
        id="formFrame"
        className="formFrame"
        display="initial"
        position="relative"
      />
    </div>
  );
};

export default PartnerRegisterSale;
// import React, { useEffect, useState } from "react";
// import { Box, Button, TextField } from "@mui/material";
// import { Formik, Field, Form, ErrorMessage, useFormikContext } from "formik";
// import * as yup from "yup";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import Header from "../../components/Header";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import { styled } from "@mui/material/styles";

// const VisuallyHiddenInput = styled("input")({
//   clip: "rect(0 0 0 0)",
//   clipPath: "inset(50%)",
//   height: 1,
//   overflow: "hidden",
//   position: "absolute",
//   bottom: 0,
//   left: 0,
//   whiteSpace: "nowrap",
//   width: 1,
// });

// const AgentRegistrationForm = () => {
//   const { setFieldValue, touched, errors, handleSubmit } = useFormikContext();

//   const isNonMobile = useMediaQuery("(min-width:600px)");

//   return (
//     <Form>
//       <Box
//         display="grid"
//         gap="30px"
//         gridTemplateColumns="repeat(4, minmax(0, 1fr))"
//         sx={{
//           "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
//         }}
//       >
//         <Field
//           as={TextField}
//           fullWidth
//           variant="filled"
//           type="text"
//           label="First Name"
//           name="firstName"
//           error={!!touched.firstName && !!errors.firstName}
//           helperText={touched.firstName && errors.firstName}
//           sx={{ gridColumn: "span 2" }}
//         />
//         <Field
//           as={TextField}
//           fullWidth
//           variant="filled"
//           type="text"
//           label="Last Name"
//           name="lastName"
//           error={!!touched.lastName && !!errors.lastName}
//           helperText={touched.lastName && errors.lastName}
//           sx={{ gridColumn: "span 2" }}
//         />
//         <Field
//           as={TextField}
//           fullWidth
//           variant="filled"
//           type="text"
//           label="Phone Number"
//           name="mobile"
//           error={!!touched.mobile && !!errors.mobile}
//           helperText={touched.mobile && errors.mobile}
//           sx={{ gridColumn: "span 2" }}
//         />
//         <Field
//           as={TextField}
//           fullWidth
//           variant="filled"
//           type="text"
//           label="Email"
//           name="email"
//           error={!!touched.email && !!errors.email}
//           helperText={touched.email && errors.email}
//           sx={{ gridColumn: "span 2" }}
//         />
//         <Button
//           component="label"
//           variant="contained"
//           startIcon={<CloudUploadIcon />}
//         >
//           Upload Drivers License
//           <VisuallyHiddenInput
//             name="driversLicense"
//             type="file"
//             accept="image/*"
//             onChange={(event) => {
//               setFieldValue("driversLicense", event.currentTarget.files[0]);
//             }}
//           />
//         </Button>
//       </Box>
//       <Box display="flex" justifyContent="center" mt="20px">
//         <Button type="submit" color="secondary" variant="contained">
//           Place Order
//         </Button>
//       </Box>
//     </Form>
//   );
// };

// const AgentRegistration = (props) => {
//   const Agent = props.agent;
//   const initialValues = {
//     firstName: "",
//     lastName: "",
//     email: "",
//     mobile: "",
//     driversLicense: null,
//   };

//   const phoneRegExp =
//     /^(?:(?:\+?[1-9]{1,4}[ -]?)|(?:\([0-9]{2,3}\)[ -]?)|(?:[0-9]{2,4}[ -]?))*[0-9]{10}$/;

//   const checkoutSchema = yup.object().shape({
//     firstName: yup.string().required("First Name is required"),
//     lastName: yup.string().required("Last Name is required"),
//     email: yup.string().email("Invalid email").required("Email is required"),
//     mobile: yup
//       .string()
//       .matches(phoneRegExp, "Invalid phone number")
//       .test(
//         "len",
//         "Must be exactly 10 characters",
//         (val) => val && val.toString().length === 10
//       )
//       .required("Mobile is required"),
//     driversLicense: yup.mixed().required("File is required"),
//   });

//   const handleSubmit = async (values) => {
//     if (Agent !== undefined) {
//       values.partnerID = Agent.PartnerID;

//       const formData = new FormData();

//       // Append JSON data
//       formData.append("json", JSON.stringify(values));

//       // Append the image file
//       formData.append("image", values.driversLicense);

//       try {
//         const response = await fetch(
//           "https://us-central1-argon-fx-383518.cloudfunctions.net/agentRegistration",
//           {
//             method: "POST",
//             body: formData,
//           }
//         );

//         if (response.ok) {
//           console.log("Form submitted successfully!");
//         } else {
//           console.error("Error submitting form");
//         }
//       } catch (error) {
//         console.error(error);
//       }
//     }
//   };

//   // const handleSubmit = async (values) => {
//   //   if (Agent != undefined) {
//   //     values.partnerID = Agent.PartnerID;
//   //     try {
//   //       const response = await fetch(
//   //         "https://us-central1-argon-fx-383518.cloudfunctions.net/agentRegistration", // Replace with your Cloud Function URL
//   //         {
//   //           method: "POST",
//   //           headers: {
//   //             "Content-Type": "multipart/form-data",
//   //           },
//   //           body: JSON.stringify(values),
//   //         }
//   //       );
//   //       console.log("inner: ", values);
//   //       console.log("inner strngfy: ", JSON.stringify(values));

//   //       if (response.ok) {
//   //         // Form submitted successfully
//   //         console.log("Form submitted successfully!");
//   //       } else {
//   //         // Error submitting form
//   //         console.error("Error submitting form");
//   //       }
//   //     } catch (error) {
//   //       console.error(error);
//   //     }
//   //   }
//   //   console.log(values);
//   // };

//   return (
//     <div className="m-5">
//       <Header title="Agent Registration" subtitle="Register an Agent" />
//       <Formik
//         initialValues={initialValues}
//         validationSchema={checkoutSchema}
//         onSubmit={(values) => {
//           handleSubmit(values);
//         }}
//       >
//         <AgentRegistrationForm />
//       </Formik>
//     </div>
//   );
// };

// export default AgentRegistration;

// // CREATE TABLE agent_registration (
// //   id INT PRIMARY KEY AUTO_INCREMENT,
// //   driversLicense VARCHAR(255),
// //   email VARCHAR(255),
// //   firstName VARCHAR(255),
// //   lastName VARCHAR(255),
// //   mobile VARCHAR(25),
// //   partnerID VARCHAR(25)
// // );
