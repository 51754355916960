import React, { useEffect } from "react";
import { Box } from "@mui/material";

const RolePlayTraining = () => {
  useEffect(() => {
    // Dynamically create and append the Calendly script to the document head
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    // Cleanup script on component unmount (if necessary)
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array to run effect only on mount

  return (
    <Box
      mt={"-13px"}
      justifyContent={"center"}
      alignItems={"center"}
      display={"grid"}
    >
      {/* Calendly widget */}
      <div
        className="calendly-inline-widget"
        data-url="https://calendly.com/boris-bonillo/sales-interview?hide_event_type_details=1&hide_gdpr_banner=1"
        data-resize="true"
        style={{ width: "700px", height: "600px" }}
      ></div>
    </Box>
  );
};

export default RolePlayTraining;
