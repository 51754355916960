import React from "react";
import Iframe from "react-iframe";

const SpectrumMDP = () => {
  return (
    <div>
      <Iframe
        url="https://docs.google.com/spreadsheets/d/e/2PACX-1vRqQ1lZJRGYK47_WGgL79aBCDkOmF1j5sy0qvYd9t7t65SrRnhkMsJaNa8n_FKVGsmlTZpg3kP_Tkfo/pubhtml?widget=true&amp;headers=false"
        width="100%"
        height="950px"
        id="formFrame"
        className="formFrame"
        display="initial"
        position="relative"
      />
    </div>
  );
};

export default SpectrumMDP;
