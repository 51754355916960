import React, { useEffect } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import Iframe from "react-iframe";

const SpectrumMobile = () => {
  useEffect(() => {}, []);
  return (
    <Box mt={"13px"}>
      <iframe
        src="https://docs.google.com/presentation/d/e/2PACX-1vQqEsYb0k5YaAq6J8IsIm6e2rGSgOu4AH1HIm18osnUFfPNJqi7Y8viJnrp7QzFEDVZDdFnmb5tA5pS/embed?start=false&loop=false&delayms=3000"
        frameborder="0"
        width="1280px"
        height="749px"
        allowfullscreen="true"
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
      ></iframe>
    </Box>
  );
};

export default SpectrumMobile;
