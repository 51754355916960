import { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import { tokens } from "../../theme";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";

import { mockPieData as data } from "../../data/mockData";

const Circle = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const Agent = props.agent;
  const Data = props.data;
  const Period = props.period;
  const [mockPieData, setMockPieData] = useState();
  const [internet, setInternet] = useState();
  const [video, setVideo] = useState();
  const [voice, setVoice] = useState();
  const [mobile, setMobile] = useState();
  const [total, setTotal] = useState(0);
  const [colorScale, setColorScale] = useState("#4cceac");

  useEffect(() => {
    if (Agent && Agent.PartnerID) {
      dataChurningMachine();
    }
  }, [Period, Data, colorScale]);

  const dataChurningMachine = () => {
    let internet = 0;
    let video = 0;
    let voice = 0;
    let mobile = 0;
    let All = 0;
    let TotalCs = 0;
    Data.forEach((item) => {
      let fiscalCaps = item.FiscalMonth.toUpperCase();
      let periodCaps = Period.toUpperCase();
      if (
        Period == "all" ||
        (fiscalCaps.search(periodCaps) >= 0 && Period != "")
      ) {
        // console.log("test occ match: ", periodCaps, " : ", fiscalCaps);
        if (item.SalesConnects == 1) {
          All++;
        }
        if (item.PSU == "Internet" && item.SalesCanceled == 1) {
          internet++;
        }
        if (item.PSU == "Video" && item.SalesCanceled == 1) {
          video++;
        }
        if (item.PSU == "Voice" && item.SalesCanceled == 1) {
          voice++;
        }
        if (item.PSU == "Mobile" && item.SalesCanceled == 1) {
          mobile++;
        }

        TotalCs = internet + video + voice + mobile;
        setInternet(internet);
        setVideo(video);
        setVoice(voice);
        setMobile(mobile);
        setTotal(TotalCs);
        if (((TotalCs / All) * 100).toFixed(2) > 10) {
          setColorScale("#e22839");
        }
      }
    });

    // setInternet(internet);
    // setVideo(video);
    // setVoice(voice);
    // setMobile(mobile);
    // setTotal(internet + video + voice + mobile);
    // if (((total / All) * 100).toFixed(2) > 10) {
    //   setColorScale("#e22839");
    // }

    // setMockPieData(newMockPieData);
  };

  return (
    <Box
      width="100%"
      // border="2px solid red"
      display="grid"
      gridTemplateColumns="repeat(2, 1fr)"
      padding="5px"
    >
      <Box gridColumn="span 2">
        <Typography variant="h5" fontWeight="600">
          Orders canceled
        </Typography>
      </Box>
      <Box borderLeft="1px dashed #4cceac" paddingLeft="6px">
        <Box>Internet: {internet}</Box>
        <Box>Video: {video}</Box>
        <Box>Voice: {voice}</Box>
        <Box>Mobile: {mobile}</Box>
      </Box>
      <Box
        borderRadius="50px"
        height="85px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography color={colorScale} variant="h1" fontWeight="600">
          {total}
        </Typography>
      </Box>
    </Box>
  );
};

export default Circle;
