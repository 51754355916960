import React from "react";
import Iframe from "react-iframe";

const PartnerRegisterSale = () => {
  return (
    <div>
      <Iframe
        url="https://fs10.formsite.com/yU3au7/umyefu28uf/index"
        width="100%"
        height="1250px"
        id="formFrame"
        className="formFrame"
        display="initial"
        position="relative"
      />
    </div>
  );
};

export default PartnerRegisterSale;
