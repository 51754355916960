import { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import { tokens } from "../../theme";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";

import { mockPieData as data } from "../../data/mockData";

const Circle = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const Agent = props.agent;
  const Data = props.data;
  const Period = props.period;
  const [mockPieData, setMockPieData] = useState();
  const [internet, setInternet] = useState();
  const [video, setVideo] = useState();
  const [voice, setVoice] = useState();
  const [mobile, setMobile] = useState();
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (Agent && Agent.PartnerID) {
      dataChurningMachine();
    }
  }, [Period, Data]);

  const dataChurningMachine = () => {
    let internet = 0;
    let video = 0;
    let voice = 0;
    let mobile = 0;
    let months = {
      JANUARY: "1/",
      FEBRUARY: "2/",
      MARCH: "3/",
      APRIL: "4/",
      MAY: "5/",
      JUNE: "6/",
      JULY: "7/",
      AUGUST: "8/",
      SEPTEMBER: "9/",
      OCTOBER: "10",
      NOVEMBER: "11",
      DECEMBER: "12",
    };
    Data.forEach((item) => {
      let month = "";
      let periodCaps = Period.toUpperCase();
      let periodNum = months[periodCaps];

      if (item.FiscalMonth) {
        month = item.FiscalMonth.slice(0, 2);
      }

      if (Period == "all" || (month == periodNum && Period != "")) {
        if (item.InternetPSU === 1) {
          internet++;
        }
        if (item.VideoPSU === 1) {
          video++;
        }
        if (item.VoicePSU === 1) {
          voice++;
        }
        if (item.MobilePSU === 1) {
          mobile++;
        }
        setInternet(internet);
        setVideo(video);
        setVoice(voice);
        setMobile(mobile);
        setTotal(internet + video + voice + mobile);
      }
    });

    // setInternet(internet);
    // setVideo(video);
    // setVoice(voice);
    // setMobile(mobile);
    // setTotal(internet + video + voice + mobile);

    // setMockPieData(newMockPieData);
  };

  return (
    <Box
      width="100%"
      // border="2px solid red"
      display="grid"
      gridTemplateColumns="repeat(2, 1fr)"
      padding="5px"
    >
      <Box gridColumn="span 2">
        <Typography variant="h5" fontWeight="600">
          Total unit sales
        </Typography>
      </Box>
      <Box borderLeft="1px dashed #4cceac" paddingLeft="6px">
        <Box>Internet: {internet}</Box>
        <Box>Video: {video}</Box>
        <Box>Voice: {voice}</Box>
        <Box>Mobile: {mobile}</Box>
      </Box>
      <Box
        borderRadius="50px"
        height="85px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          color={colors.greenAccent[500]}
          variant="h1"
          fontWeight="600"
        >
          {total}
        </Typography>
      </Box>
    </Box>
  );
};

export default Circle;
