import { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import { tokens } from "../../theme";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";

import { mockPieData as data } from "../../data/mockData";

const Circle = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const Agent = props.agent;
  const Data = props.data;
  const Period = props.period;

  const [total, setTotal] = useState();
  const [all, setAll] = useState();
  const [percentage, setPercentage] = useState();
  const [colorScale, setColorScale] = useState("#4cceac");

  useEffect(() => {
    if (Agent && Agent.PartnerID) {
      console.log("test CBT period: ", Period);
      dataChurningMachine();
    }
  }, [Data, colorScale, Period]);

  const dataChurningMachine = () => {
    let internet = 0;
    let video = 0;
    let voice = 0;
    let mobile = 0;
    let All = 0;
    let TotalCBs = 0;
    Data.forEach((item) => {
      let fiscalCaps = item.FiscalMonth.toUpperCase();
      let periodCaps = Period.toUpperCase();
      if (
        Period == "all" ||
        (fiscalCaps.search(periodCaps) >= 0 && Period != "")
      ) {
        if (item.SalesConnects == 1) {
          All++;
        }
        if (
          item.PSU == "Internet" &&
          item.Disconnects == 1 &&
          item.Survival90Day == 0
        ) {
          internet++;
          TotalCBs++;
        }
        if (
          item.PSU == "Video" &&
          item.Disconnects == 1 &&
          item.Survival90Day == 0
        ) {
          video++;
          TotalCBs++;
        }
        if (
          item.PSU == "Voice" &&
          item.Disconnects == 1 &&
          item.Survival90Day == 0
        ) {
          voice++;
          TotalCBs++;
        }
        if (
          item.PSU == "Mobile" &&
          item.Disconnects == 1 &&
          item.Survival90Day == 0
        ) {
          mobile++;
          TotalCBs++;
        }
        setTotal(internet + video + voice + mobile);
        setAll(All);
        setPercentage(((TotalCBs / All) * 100).toFixed(2));
      }
    });

    // setTotal(internet + video + voice + mobile);
    // setAll(All);
    // setPercentage(((total / all) * 100).toFixed(2));

    if (percentage > 15) {
      setColorScale("#e22839");
    }

    // setMockPieData(newMockPieData);
  };

  return (
    <Box
      gridColumn="span 1"
      width="100%"
      // border="2px solid red"
      display="grid"
      gridTemplateColumns="repeat(2, 1fr)"
      padding="5px"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        backgroundColor={colorScale}
        height="85px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        gridColumn="span 2"
        textAlign="center"
        borderRadius="25px"
        color="white"
      >
        <Typography variant="h5" fontWeight="600">
          Chargebacks: {percentage || 0}%
        </Typography>
      </Box>
    </Box>
  );
};

export default Circle;
