import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";
import axios from "axios";
import { toast } from "react-toastify";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [userIP, setUserIP] = useState("");
  const [browserId, setBrowserId] = useState("");
  const [ipAuth, setIPAuth] = useState(false);
  const { createUser } = UserAuth();
  const notify = (x) => toast(x);
  const notifyError = (x) => toast.error(x);

  useEffect(() => {
    notify("READ WARNING AT BOTTOM!");
    getCurrentBrowserFingerPrint().then((res) => {
      setBrowserId(res);
      console.log("Device ID: " + browserId);
    });
  }, []);

  async function Validate() {
    console.log("Device ID: " + browserId);
    axios
      .get("https://api.ipify.org?format=json")
      .then((response) => {
        const ipData = response.data;
        setUserIP(ipData.ip);
        return ipData.ip; // Return the IP to the next .then() block
      })
      .then(async (userIP) => {
        let payload = { ip: userIP, email: email, browserID: browserId };
        console.log("payload: ", payload);

        // Fetch the user's IP
        const cloudFunctionResponse = await fetch(
          "https://us-central1-argon-fx-383518.cloudfunctions.net/getUserIP",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );

        const data = await cloudFunctionResponse.json();
        if (data !== "" && data.match == true) {
          console.log("response: ", data);
          console.log("User verified");
          setIPAuth(true);
          createAccount();
        } else {
          setIPAuth(false);
          alert(
            "You are trying to connect from an unauthorized IP or Email address. If you think this is a mistake reach out to Vomtel."
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching user IP:", error);
      });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    await Validate();
  };

  const createAccount = async () => {
    try {
      await createUser(email, password);
      navigate("/account");
    } catch (e) {
      setError(e.message);
      alert(e.message);
      console.log(e.message);
    }
  };

  return (
    <div className="max-w-[700px] mx-auto my-16 p-4">
      <div>
        <h1 className="text-2xl font-bold">Sign up</h1>
        <p className="">
          Already have an account?{" "}
          <Link to="/" className="underline">
            Sign in
          </Link>{" "}
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col py-2">
          <label className="py-2 font-medium">Email</label>
          <input
            onChange={(e) => setEmail(e.target.value)}
            className="border p-3 text-black"
            type="email"
          />
        </div>
        <div className="flex flex-col py-2">
          <label className="py-2 font-medium">password</label>
          <input
            onChange={(e) => setPassword(e.target.value)}
            className="border p-3 text-black"
            type="password"
          />
        </div>
        <button className="border border-blue-500 bg-blue-600 hover:bg-blue-500 w-full p-4 my-2 text-white">
          Sign up
        </button>
      </form>
      <p>
        <b>WARNING:</b> Once you claim your account you will be <b>LOCKED</b> to
        the same <b>IP</b>, <b>Computer,</b> AND, <b>Browser</b> from this point
        forward. If you already have an account please{" "}
        <Link to="/sigin" className="underline">
          Sign in
        </Link>
        . If you are having issues checkout our{" "}
        <Link to="/faq" className="underline">
          FAQ.
        </Link>{" "}
        If you are still having issues please contact your Account Manager.
      </p>
    </div>
  );
};

export default Signup;
