import React from "react";
import { useEffect, useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import Header from "../components/Header";
import { tokens } from "../theme";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";

const NewsViewAll = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const Agent = props.agent;

  useEffect(() => {
    fetchDataFromDatabase();
  }, []);

  const fetchDataFromDatabase = async () => {
    await fetch(
      "https://us-central1-argon-fx-383518.cloudfunctions.net/getAllBlogs"
    )
      .then((response) => response.json())
      .then((data) => {
        // Do something with the data, e.g. update component state
        setData(data);
        console.log(data);
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };

  return (
    <Box
      m="20px"
      display={"grid"}
      columnGap={"0px"}
      rowGap={"15px"}
      maxWidth={"70vw"}
      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
    >
      <Box
        sx={{ gridColumn: "span 4" }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="mb-0"
      >
        <Header title="News" subtitle="Vomtel & Spectrum news" />
        <Box>
          {Agent !== undefined && Agent.Hierarchy == "Master" && (
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
              component={Link}
              to="/createPost"
            >
              <NoteAddIcon sx={{ mr: "10px" }} />
              New post
            </Button>
          )}
        </Box>
      </Box>

      {data.map((element) => {
        return (
          <Box
            sx={{
              gridColumn: "span 1",
              backgroundImage: `url("${
                process.env.PUBLIC_URL +
                (element.img ? element.img : "assets/spectrum blank.jpg")
              }")`,
            }}
            key={element.id}
            width={"144px"}
            height={"144px"}
            position={"relative"}
            marginBottom={"15px"}
            justifySelf={"center"}
            component={Link}
            to={"/showPost"}
            state={{ title: element.title, body: element.body, id: element.id }}
          >
            <Box className="m-2" position={"absolute"} bottom={"0"}>
              <Typography color={colors.greenAccent[500]} variant="h5">
                {element.title}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default NewsViewAll;
