import React from "react";
import Iframe from "react-iframe";

const EventForm = () => {
  return (
    <div>
      <Iframe
        url="https://www.powr.io/form-builder/i/35934877#page"
        width="100%"
        height="700px"
        id="formFrame"
        className="formFrame"
        display="initial"
        position="relative"
      />
    </div>
  );
};

export default EventForm;
