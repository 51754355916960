import React from "react";
import { useLocation } from "react-router-dom";
import { Box, Button, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";

const ShowPost = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const { title, body, id } = location.state;
  const navigate = useNavigate();
  console.log(location.state);
  const Agent = props.agent;

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let formData = { id: id };

    try {
      const response = await fetch(
        "https://us-central1-argon-fx-383518.cloudfunctions.net/deletePost", // Replace with your Cloud Function URL
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      console.log("inner: ", formData);
      console.log("inner strngfy: ", JSON.stringify(formData));

      if (response.ok) {
        // Form submitted successfully
        console.log("Form deleted successfully!");
        navigate("/news");
      } else {
        // Error submitting form
        console.error("Error deleting form");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      className="m-5"
      display="grid"
      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
    >
      <Header
        title={title}
        subtitle="posted by Vomtel"
        sx={{ gridColumn: "span 4" }}
      />
      <Box
        dangerouslySetInnerHTML={{ __html: body }}
        sx={{
          gridColumn: "span 4",
          "& h1": { fontSize: "26px", fontWeight: "bold" },
          "& h2": { fontSize: "22px", fontWeight: "bold" },
          "& h3": { fontSize: "18px", fontWeight: "bold" },
          "& p": { fontSize: "14px", lineHeight: "1.5" },
          "& a": { textDecoration: "underline" },
        }}
      ></Box>
      <Box
        sx={{ gridColumn: "span 4" }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="mb-0"
      >
        <Box></Box>
        {Agent !== undefined && Agent.Hierarchy == "Master" && (
          <Box>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginRight: "10px",
              }}
              component={Link}
              to="/editPost"
              state={{ title: title, body: body, id: id }}
            >
              <EditIcon sx={{ mr: "10px" }} />
              edit
            </Button>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
              onClick={handleFormSubmit}
            >
              <DeleteIcon sx={{ mr: "10px" }} />
              Delete
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ShowPost;
