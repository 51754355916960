import React from "react";
import Header from "../../components/Header";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";

const Training = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box
      className="m-5"
      display="grid"
      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
    >
      <Header title="Reports" sx={{ gridColumn: "span 4" }} />
      <Box
        sx={{ gridColumn: "span 4" }}
        className="mb-4"
        display="grid"
        gridTemplateColumns="repeat(4, minmax(0, auto))"
        columnGap={"20px"}
        rowGap={"20px"}
        justifyContent={"start"}
      >
        <Box sx={{ gridColumn: "span 4" }} className="mb-1">
          <Typography color={colors.greenAccent[500]} variant="h3">
            Cable
          </Typography>
        </Box>
        <Box sx={{ gridColumn: "span 1", height: "auto", width: "200px" }}>
          <a href={"/SpectrumReporting"} target="">
            <img
              src={process.env.PUBLIC_URL + "assets/Logos/Spectrum Button.png"}
              alt=""
            />
          </a>
        </Box>
        <Box sx={{ gridColumn: "span 1", height: "auto", width: "200px" }}>
          <a href={"#"}>
            <img
              src={process.env.PUBLIC_URL + "assets/Logos/Xfinity Button G.png"}
              alt=""
            />
          </a>
        </Box>
        <Box sx={{ gridColumn: "span 1", height: "auto", width: "200px" }}>
          <a href="#">
            <img
              src={process.env.PUBLIC_URL + "assets/Logos/Optimum Button G.png"}
              alt=""
            />
          </a>
        </Box>
        <Box sx={{ gridColumn: "span 1", height: "auto", width: "200px" }}>
          <a href={"#"}>
            <img
              src={process.env.PUBLIC_URL + "assets/Logos/Cox Button G.png"}
              alt=""
            />
          </a>
        </Box>
      </Box>
      <Box
        sx={{ gridColumn: "span 4" }}
        className="mb-4"
        display="grid"
        gridTemplateColumns="repeat(4, minmax(0, auto))"
        columnGap={"20px"}
        rowGap={"20px"}
        justifyContent={"start"}
      >
        <Box sx={{ gridColumn: "span 4" }} className="mt-4 mb-1">
          <Typography color={colors.greenAccent[500]} variant="h3">
            Telco
          </Typography>
        </Box>
        <Box sx={{ gridColumn: "span 1", height: "auto", width: "200px" }}>
          <a href={"#"}>
            <img
              src={process.env.PUBLIC_URL + "assets/Logos/ATT Button G.png"}
              alt=""
            />
          </a>
        </Box>
        <Box sx={{ gridColumn: "span 1", height: "auto", width: "200px" }}>
          <a href={"#"}>
            <img
              src={
                process.env.PUBLIC_URL + "assets/Logos/Frontier Button G.png"
              }
              alt=""
            />
          </a>
        </Box>
        <Box sx={{ gridColumn: "span 1", height: "auto", width: "200px" }}>
          <a href={"#"}>
            <img
              src={
                process.env.PUBLIC_URL + "assets/Logos/Windstream Button G.png"
              }
              alt=""
            />
          </a>
        </Box>
        <Box sx={{ gridColumn: "span 1", height: "auto", width: "200px" }}>
          <a href={"#"}>
            <img
              src={
                process.env.PUBLIC_URL + "assets/Logos/Earthlink Button G.png"
              }
              alt=""
            />
          </a>
        </Box>
      </Box>
      <Box
        sx={{ gridColumn: "span 4" }}
        className="mb-4"
        display="grid"
        gridTemplateColumns="repeat(4, minmax(0, auto))"
        columnGap={"20px"}
        rowGap={"20px"}
        justifyContent={"start"}
      >
        <Box sx={{ gridColumn: "span 4" }} className="mt-4 mb-1">
          <Typography color={colors.greenAccent[500]} variant="h3">
            Satellite
          </Typography>
        </Box>
        <Box sx={{ gridColumn: "span 1", height: "auto", width: "200px" }}>
          <a href={"#"}>
            <img
              src={process.env.PUBLIC_URL + "assets/Logos/Viasat Button G.png"}
              alt=""
            />
          </a>
        </Box>
      </Box>
      <Box
        sx={{ gridColumn: "span 4" }}
        className="mb-4"
        display="grid"
        gridTemplateColumns="repeat(4, minmax(0, auto))"
        columnGap={"20px"}
        rowGap={"20px"}
        justifyContent={"start"}
      >
        <Box sx={{ gridColumn: "span 4" }} className="mt-4 mb-1">
          <Typography color={colors.greenAccent[500]} variant="h3">
            Tele
          </Typography>
        </Box>
        <Box sx={{ gridColumn: "span 1", height: "auto", width: "200px" }}>
          <a href={"#"}>
            <img
              src={process.env.PUBLIC_URL + "assets/Logos/DTV Button G.png"}
              alt=""
            />
          </a>
        </Box>
      </Box>
    </Box>
  );
};

export default Training;
