import { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import { tokens } from "../../theme";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";

import { mockPieData as data } from "../../data/mockData";

const Circle = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const Agent = props.agent;
  const Data = props.data;
  const Period = props.period;
  const [mockPieData, setMockPieData] = useState();
  const [completed, setCompleted] = useState();
  const [total, setTotal] = useState();
  const [colorScale, setColorScale] = useState("#4cceac");

  useEffect(() => {
    if (Agent && Agent.PartnerID) {
      dataChurningMachine();
    }
  }, [Period, Data]);

  const dataChurningMachine = () => {
    let total = 0;
    let completed = 0;
    let months = {
      JANUARY: "01",
      FEBRUARY: "02",
      MARCH: "03",
      APRIL: "04",
      MAY: "05",
      JUNE: "06",
      JULY: "07",
      AUGUST: "08",
      SEPTEMBER: "09",
      OCTOBER: "10",
      NOVEMBER: "11",
      DECEMBER: "12",
    };

    Data.forEach((item) => {
      let month = "";
      let periodCaps = Period.toUpperCase();
      let periodNum = months[periodCaps];

      if (item.DateEntered) {
        month = item.DateEntered.slice(0, 2);
      }

      if (Period == "all" || (month == periodNum && Period != "")) {
        console.log("test mo: ", months[periodCaps], " vs: ", month);
        total++;
        if (item.CustomerAccountNumber !== "") {
          completed++;
        }
        setCompleted(completed);
        setTotal(total);
        if (((completed / total) * 100).toFixed(2) < 85) {
          setColorScale("#e22839");
        } else {
          setColorScale("#4cceac");
        }
      }
    });

    // setCompleted(completed);
    // setTotal(total);
    // if (((completed / total) * 100).toFixed(2) < 85) {
    //   setColorScale("#e22839");
    // }

    // setMockPieData(newMockPieData);
  };

  return (
    <Box
      width="100%"
      // border="2px solid red"
      display="grid"
      gridTemplateColumns="repeat(2, 1fr)"
      padding="5px"
    >
      <Box gridColumn="span 2">
        <Typography variant="h5" fontWeight="600">
          Entered Orders
        </Typography>
      </Box>
      <Box borderLeft="1px dashed #4cceac" paddingLeft="6px">
        <Box>Entered: {total}</Box>
        <Box>Completed: {completed}</Box>
      </Box>
      <Box
        borderRadius="50px"
        height="85px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography color={colorScale} variant="h1" fontWeight="600">
          {total || 0}
        </Typography>
      </Box>
    </Box>
  );
};

export default Circle;
