import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Profile = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  // const [PartnerDetails, setPartnerDetails] = useState({});

  let Agent = props.agent;
  // const PartnerObject = props.PartnerObject;

  const location = useLocation();
  const PartnerObject = props.PartnerObject;
  const PartnerDetails = props.PartnerDetails;

  useEffect(() => {
    if (Agent && Agent.PartnerID && data.length == 0) {
      //3344556677
      console.log("if statement", Agent.PartnerID);
      // Start call chain
      console.log("Partner Object PROP: ", PartnerObject);
      console.log("PartnerDetails: ", PartnerDetails.FirstName);
    }
  }, [Agent, PartnerObject]);

  return (
    <Box
      m="10px 0 40px 0"
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      rowGap="15px"
      columnGap="15px"
      justifyContent={"center"}
    >
      {/* Primary owner information */}
      <Box
        sx={{ gridColumn: "span 4", boxShadow: 3 }}
        backgroundColor="#f2f0f0"
        padding="15px"
      >
        <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
          Primary Owner Information
        </Typography>
        <Typography
          color={colors.greenAccent[500]}
          variant="h5"
          fontWeight="600"
        >
          {PartnerDetails.FirstName + " " + PartnerDetails.LastName}
        </Typography>
        <Typography
          color={colors.greenAccent[500]}
          variant="h5"
          fontWeight="600"
        >
          {PartnerDetails.EmailDisplay}
        </Typography>
        <Typography
          color={colors.greenAccent[500]}
          variant="h5"
          fontWeight="600"
        >
          {PartnerDetails.Mobile}
        </Typography>
      </Box>
      <Box
        m="0px 0 0px 0"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        columnGap="15px"
        padding="15px"
        sx={{ gridColumn: "span 8", boxShadow: 3 }}
        backgroundColor="#f2f0f0"
        justifyContent={"center"}
      >
        <Box sx={{ gridColumn: "span 12" }}>
          <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
            Commission Tiers
          </Typography>
        </Box>
        <Box sx={{ gridColumn: "span 4" }} backgroundColor="#f2f0f0">
          <Typography
            color={colors.greenAccent[500]}
            variant="h5"
            fontWeight="600"
          >
            Volume Tier:
          </Typography>
          <Typography color={colors.grey[100]} variant="h5">
            {PartnerDetails.CommissionTier}
          </Typography>
        </Box>
        <Box sx={{ gridColumn: "span 4" }} backgroundColor="#f2f0f0">
          <Typography
            color={colors.greenAccent[500]}
            variant="h5"
            fontWeight="600"
          >
            Chargeback Tier:
          </Typography>
          <Typography color={colors.grey[100]} variant="h5">
            {PartnerDetails.CBTier}
          </Typography>
        </Box>
        <Box sx={{ gridColumn: "span 4" }} backgroundColor="#f2f0f0">
          <Typography
            color={colors.greenAccent[500]}
            variant="h5"
            fontWeight="600"
          >
            Mobile Tier:
          </Typography>
          <Typography color={colors.grey[100]} variant="h5">
            {PartnerDetails.MobileTier}
          </Typography>
        </Box>
      </Box>
      <Box
        m="0px 0 40px 0"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        rowGap="15px"
        columnGap="15px"
        padding="15px"
        sx={{ gridColumn: "span 4", boxShadow: 3 }}
        backgroundColor="#f2f0f0"
        justifyContent={"center"}
      >
        <Box sx={{ gridColumn: "span 12" }} backgroundColor="#f2f0f0">
          <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
            Company Information
          </Typography>
          <Typography color={colors.greenAccent[500]} variant="h5">
            {PartnerDetails.DisplayName}
          </Typography>
          <Typography color={colors.greenAccent[500]} variant="h5">
            {PartnerDetails.Address}
          </Typography>
          <Typography color={colors.greenAccent[500]} variant="h5">
            {PartnerDetails.AddressLine2}
          </Typography>
          <Typography color={colors.greenAccent[500]} variant="h5">
            {PartnerDetails.City} {PartnerDetails.State} {PartnerDetails.Zip}
          </Typography>
          <Typography color={colors.greenAccent[500]} variant="h5">
            P: {PartnerDetails.Store}
          </Typography>
          <Typography color={colors.greenAccent[500]} variant="h5">
            E: {PartnerDetails.EmailDisplay}
          </Typography>
        </Box>
      </Box>
      <Box
        m="0px 0 40px 0"
        display="grid"
        gridTemplateColumns="repeat(1, 1fr)"
        rowGap="15px"
        columnGap="15px"
        padding="15px"
        sx={{ gridColumn: "span 4", boxShadow: 3 }}
        backgroundColor="#f2f0f0"
        justifyContent={"center"}
      >
        <Button variant="contained" color="secondary">
          Partner Application
        </Button>
        <Button variant="contained" color="secondary">
          Partner Interview
        </Button>
        <Button variant="contained" color="secondary">
          Reseller Permit/Drivers License
        </Button>
        <Button variant="contained" color="secondary">
          Drivers License
        </Button>
        <Button variant="contained" color="secondary">
          Main office Pictures
        </Button>
        <Button variant="contained" color="secondary">
          Call Center Pictures
        </Button>
      </Box>
      <Box
        m="0px 0 40px 0"
        display="grid"
        gridTemplateColumns="repeat(1, 1fr)"
        rowGap="15px"
        columnGap="15px"
        padding="15px"
        sx={{ gridColumn: "span 4", boxShadow: 3 }}
        backgroundColor="#f2f0f0"
        justifyContent={"center"}
        maxHeight={"300px"}
        overflow={"scroll"}
      >
        <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
          Agent List
        </Typography>
        {PartnerObject.Agents.map((agent, index) => (
          <Typography
            key={index} // Make sure to add a unique key for each element in the list
            color={colors.grey[100]}
            variant="h6"
          >
            {agent}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default Profile;
