import React, { useEffect } from "react";
import Iframe from "react-iframe";

const PartnerRegisterSale = () => {
  return (
    <div>
      <Iframe
        url="https://fs10.formsite.com/res/resultsReportTable?EParam=B6fiTn-RcO55hseJFOVSfTM1wxFNPIs-2CO-cxzjbepwVVFSbXf9oO8hmVj0hZPcpMf53quu5inroVFzrlTmEg"
        width="100%"
        height="850px"
        id="formFrame"
        className="formFrame"
        display="initial"
        position="relative"
      />
    </div>
  );
};

export default PartnerRegisterSale;
