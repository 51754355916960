import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { tokens } from "../theme";
import { Link } from "react-router-dom";
import Header from "../components/Header";

import MOMBarChart from "../components/BarCharts/MOMBarChart";
import TUSCircle from "../components/Circles/TotalUnitSalesCircle";
import ChargebackCircle from "../components/Circles/ChargebackCircle";
import OrdersCanceledCircle from "../components/Circles/OrdersCanceledCircle";
import EnteredOrdersCircle from "../components/Circles/EnteredOrdersCircle";
import PendingCircle from "../components/Circles/PendingCircle";
import InternetPieChart from "../components/PieCharts/InternetPieChart";
import VideoPieChart from "../components/PieCharts/VideoPieChart";
import ChargebackTile from "../components/PercentageTiles/ChargebackTile";
import CancellationTile from "../components/PercentageTiles/CancellationTile";
import GigTile from "../components/PercentageTiles/GigTile";
import TotalSalesRanking from "../components/RankingBoxes/Team";
import AddIcon from "@mui/icons-material/Add";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const Dashboard = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const Agent = props.agent;

  const [payout, setPayout] = useState([]);
  const [chargeback, setChargeback] = useState([]);
  const [enteredOrders, setEnteredOrders] = useState([]);
  const [period, setPeriod] = useState("all");

  const handleChange = (event) => {
    let food = event.target.value;
    setPeriod(food);
    console.log("test dashboard: ", period);
  };

  useEffect(() => {
    if (Agent && Agent.PartnerID) {
      console.log("Agent: ", Agent);
      fetchPayout();
      fetchChargeback();
      fetchEnteredOrders();
    }
  }, [period, Agent]);

  // PAYOUT REPORT
  const fetchPayout = async () => {
    let url = "";
    // let payload = { AgentID: Agent.AgentID };
    let payload = { AgentID: "AGT00716" };

    console.log("fetchDataFromDatabase: ", Agent.AgentID);

    if (Agent.Hierarchy == "Master") {
      url =
        "https://us-central1-argon-fx-383518.cloudfunctions.net/getEmployeePayout";
    } else {
      url =
        "https://us-central1-argon-fx-383518.cloudfunctions.net/getEmployeePayout";
    }
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        // Do something with the data, e.g. update component state
        setPayout([]);
        setPayout(data);
        console.log("payout: ", data);
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };

  // CHARGEBACK REPORT
  const fetchChargeback = async () => {
    let url = "";
    // let payload = { AgentID: Agent.AgentID };
    let payload = { AgentID: "AGT00716" };

    console.log("fetchDataFromDatabase: ", Agent.AgentID);

    if (Agent.Hierarchy == "Master") {
      url =
        "https://us-central1-argon-fx-383518.cloudfunctions.net/getEmployeeChargeback";
    } else {
      url =
        "https://us-central1-argon-fx-383518.cloudfunctions.net/getEmployeeChargeback";
    }
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        // Do something with the data, e.g. update component state
        setChargeback([]);
        setChargeback(data);
        console.log("chargeback: ", data);
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };

  // ENTERED ORDERS REPORT
  const fetchEnteredOrders = async () => {
    let url = "";
    // let payload = { AgentID: Agent.AgentID };
    let payload = { AgentID: "AGT00716" };

    console.log("fetchDataFromDatabase: ", Agent.AgentID);

    if (Agent.Hierarchy == "Master") {
      url =
        "https://us-central1-argon-fx-383518.cloudfunctions.net/getEmployeeEnteredOrders";
    } else {
      url =
        "https://us-central1-argon-fx-383518.cloudfunctions.net/getEmployeeEnteredOrders";
    }
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        // Do something with the data, e.g. update component state
        setEnteredOrders([]);
        setEnteredOrders(data);
        console.log("EnteredOrders: ", data);
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Header title="DASHBOARD" />
          <FormControl fullWidth sx={{ mt: "-25px", mb: "0px" }} size="small">
            <InputLabel id="demo-simple-select-label">Period</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={period}
              label="Age"
              onChange={handleChange}
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"november"}>November</MenuItem>
              <MenuItem value={"december"}>December</MenuItem>
              <MenuItem value={"january"}>January</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            component={Link}
            to="/iframe"
          >
            <AddIcon sx={{ mr: "10px" }} />
            Enter Order
          </Button>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* Task Manager */}
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            marginBottom="15px"
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Task Manager
            </Typography>
          </Box>
          <Typography
            color={colors.grey[100]}
            variant="p"
            fontWeight="600"
            padding="25px"
            marginTop="10px"
          >
            Coming soon..
          </Typography>
          {/* {mockTransactions.map((transaction, i) => (
            <Box
              key={`${transaction.txId}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {transaction.txId}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {transaction.user}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{transaction.date}</Box>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                ${transaction.cost}
              </Box>
            </Box>
          ))} */}
        </Box>
        {/* ROW 1 */}
        {/* super box start */}
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          alignItems="center"
          justifyContent="center"
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap="0px"
          gridRow="span 3"
          overflow="hidden"
        >
          <Box
            gridColumn="span 1"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="120px"
            width="100%"
          >
            <TUSCircle agent={Agent} data={payout} period={period} />
          </Box>
          <Box
            gridColumn="span 1"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="120px"
            width="100%"
          >
            <ChargebackCircle agent={Agent} data={chargeback} period={period} />
          </Box>
          <Box
            gridColumn="span 1"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="120px"
            width="100%"
          >
            <OrdersCanceledCircle
              agent={Agent}
              data={chargeback}
              period={period}
            />
          </Box>
          {/* <Box
            gridColumn="span 1"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="120px"
            width="100%"
          >
            <PendingCircle agent={Agent} data={chargeback} />
          </Box> */}
          <Box
            gridColumn="span 1"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="120px"
            width="100%"
          >
            <PendingCircle agent={Agent} data={chargeback} period={period} />
          </Box>
          <Box
            gridColumn="span 1"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="120px"
            width="100%"
          >
            <EnteredOrdersCircle
              agent={Agent}
              data={enteredOrders}
              period={period}
            />
          </Box>
        </Box>
        {/* super box end */}
        {/* <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="0"
            subtitle="Total"
            progress="0.75"
            increase="+14%"
            icon={
              <AttachMoneyIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="0"
            subtitle="Total"
            progress="0.75"
            increase="+14%"
            icon={
              <AttachMoneyIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="0"
            subtitle="Total"
            progress="0.75"
            increase="+14%"
            icon={
              <AttachMoneyIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="0"
            subtitle="Pending"
            progress="0.50"
            increase="+21%"
            icon={
              <HourglassTopIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="0"
            subtitle="CB"
            progress="0.30"
            increase="+5%"
            icon={
              <PersonAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="0"
            subtitle="Entered"
            progress="0.80"
            increase="+43%"
            icon={
              <MenuBookIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box> */}

        {/* ROW 2 */}

        <Box
          gridColumn="span 4"
          gridRow="span 1"
          backgroundColor={colors.primary[400]}
          overflow="auto"
          display="grid"
          gridTemplateColumns="repeat(3, 1fr)"
        >
          <ChargebackTile agent={Agent} data={chargeback} period={period} />
          <CancellationTile agent={Agent} data={chargeback} period={period} />
          <GigTile agent={Agent} data={chargeback} period={period} />
          {/* <ChargebackTile agent={Agent} data={payout} /> */}
        </Box>

        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||RANKINGS||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}

        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          {/* <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Total Sales Ranking
            </Typography>
          </Box> */}
          <TotalSalesRanking
            agent={Agent}
            data={chargeback}
            period={period}
            key={period}
          />
        </Box>

        {/* ROW 3 */}
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
          alignItems="center"
          justifyContent="center"
          display="grid"
          gridTemplateColumns="repeat(4, 1fr)"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            gridColumn="span 4"
            marginTop="-30px"
          >
            Service breakdown
          </Typography>
          <Typography variant="h5" fontWeight="600" gridColumn="span 2">
            Internet
          </Typography>
          <Typography variant="h5" fontWeight="600" gridColumn="span 2">
            Video
          </Typography>
          {/* pie box */}
          <Box
            gridColumn="span 2"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="120px"
            width="100%"
          >
            <InternetPieChart agent={Agent} data={chargeback} />
          </Box>
          <Box
            gridColumn="span 2"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="120px"
            width="100%"
          >
            <VideoPieChart agent={Agent} data={chargeback} />
          </Box>
          {/* <Box
            gridColumn="span 1"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="120px"
            width="100%"
          >
            <PieChart />
          </Box> */}
        </Box>
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Monthly Sales Trend
          </Typography>
          <Box height="250px" mt="-20px">
            <MOMBarChart
              isDashboard={true}
              agent={Agent}
              payout={payout}
              chargeback={chargeback}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
