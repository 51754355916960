import React, { useEffect } from "react";
import Iframe from "react-iframe";

const AdApproval = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://fs10.formsite.com/include/form/embedManager.js?932063555";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      const embedScript = document.createElement("script");
      embedScript.text = `
        EmbedManager.embed({
          key: "https://fs10.formsite.com/res/showFormEmbed?EParam=B6fiTn-RcO55hseJFOVSfd-nxl0gotM_knr8BrCgzCc&932063555",
          width: "100%"
        });
      `;
      document.body.appendChild(embedScript);
    };

    // Clean up the script tag on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures the effect runs once after the initial render

  return (
    <div>
      <a name="form932063555" id="formAnchor932063555"></a>
      {/* Your other JSX content */}
    </div>
  );
};

export default AdApproval;
