import { Box, Typography, useTheme } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import { tokens } from "../../theme";

const PieChart = ({ data, title }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      width="250px"
      height="250px"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography
        variant="h6"
        align="center"
        marginBottom="5px"
        marginTop="0px"
      >
        {title}
      </Typography>
      <Box height="200px" width="100%">
        <ResponsivePie
          data={data}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.2]],
          }}
          enableArcLinkLabels={false}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: "color",
            modifiers: [["darker", 2]],
          }}
          // legends={[
          //   {
          //     anchor: "left",
          //     direction: "column",
          //     justify: false,
          //     translateX: -40,
          //     translateY: 0,
          //     itemWidth: 60,
          //     itemHeight: 20,
          //     itemsSpacing: 0,
          //     symbolSize: 12,
          //     itemDirection: "left-to-right",
          //   },
          // ]}
        />
      </Box>
    </Box>
  );
};

export default PieChart;
