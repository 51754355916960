import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { useState } from "react";
import { useEffect } from "react";
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";
import axios from "axios";
import { toast } from "react-toastify";

const Landing = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [userIP, setUserIP] = useState("");
  const [ipAuth, setIPAuth] = useState(false);
  const [browserId, setBrowserId] = useState("");
  const navigate = useNavigate();
  const { signIn } = UserAuth();
  const notify = (x) => toast(x);
  const notifyError = (x) => toast.error(x);

  useEffect(() => {
    getCurrentBrowserFingerPrint().then((res) => {
      setBrowserId(res);
      console.log("Device ID: " + browserId);
    });
  }, []);

  async function Validate() {
    axios
      .get("https://api.ipify.org?format=json")
      .then((response) => {
        const ipData = response.data;
        setUserIP(ipData.ip);

        // // Define allowed IPs
        // const allowedIPs = [
        //   "172.89.227.12",
        //   "192.168.1.145",
        //   "142.136.0.111",
        //   "142.136.0.233",
        // ];

        // // Check if the IP is allowed
        // if (!allowedIPs.includes(ipData.ip)) {
        //   // If IP is not in the allowed list, notify error and stop further execution
        //   notifyError("Error: Invalid IP address. [LE001]");
        //   throw new Error("IP not allowed");
        // }

        return ipData.ip; // Return the IP to the next .then() block
      })
      .then(async (userIP) => {
        let payload = { ip: userIP, email: email, browserID: browserId };
        console.log("payload: ", payload);

        // Fetch the user's IP using your Cloud Function
        const cloudFunctionResponse = await fetch(
          "https://us-central1-argon-fx-383518.cloudfunctions.net/getUserIP", // Replace with your Cloud Function URL
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );

        const data = await cloudFunctionResponse.json();

        if (data !== "" && data.match == true) {
          console.log("response: ", data);
          setIPAuth(true);
          try {
            await signIn(email, password);
            navigate("/");
          } catch (e) {
            setError(e.message);
            notifyError("Error: Invalid Password [LE002]");
          }
        } else {
          // Call Server Errors
          setIPAuth(false);
          notifyError(data.Error);
        }
      })
      .catch((error) => {
        notifyError("Error: DB Connection Issue. [LE003]", error);
      });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    await Validate();
  };

  return (
    <div className="max-w-[700px] mx-auto my-16 p-4">
      <div>
        <h1 className="text-2xl font-bold">Sign in</h1>
        <p className="">
          <Link to="/signup" className="underline">
            Claim account
          </Link>{" "}
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col py-2">
          <label className="py-2 font-medium">Email</label>
          <input
            onChange={(e) => setEmail(e.target.value)}
            className="border p-3 text-black"
            type="email"
          />
        </div>
        <div className="flex flex-col py-2">
          <label className="py-2 font-medium">password</label>
          <input
            onChange={(e) => setPassword(e.target.value)}
            className="border p-3 text-black"
            type="password"
          />
        </div>
        <button className="border border-blue-500 bg-blue-600 hover:bg-blue-500 w-full p-4 my-2 text-white">
          Sign in
        </button>
      </form>
      <p>
        You <b>MUST</b> use the same computer and browser you used to log in
        last time or{" "}
        <Link to="/signup" className="underline">
          Claim your account
        </Link>
        . If you are having issues checkout our{" "}
        <Link to="/faq" className="underline">
          FAQ.
        </Link>{" "}
        If you are still having issues please contact your Account Manager.
      </p>
    </div>
  );
};

export default Landing;
