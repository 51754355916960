import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import { mockDataTeam } from "../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import { realData } from "../data/realData";
import { Link } from "react-router-dom";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const Team = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState([]);
  const [period, setPeriod] = useState("all");
  const [agentObjects, setAgentObjects] = useState([]);
  const [partnerArray, setPartnerArray] = useState([]);
  const [partnerObject, setPartnerObject] = useState({});

  let Agent = props.agent;

  useEffect(() => {
    if (Agent && Agent.PartnerID && data.length == 0) {
      console.log("if statement", Agent.PartnerID);
      // Start call chain
      fetchDatabyPartnerID();
    }
  }, [Agent, period]);

  // Fetch Partner Details for Marketing
  const fetchDatabyPartnerID = async () => {
    let TempPid = null;
    if (Agent.Hierarchy == "Master") {
      TempPid = "VTL1297";
    } else {
      TempPid = Agent.PartnerID;
    }
    let url =
      "https://us-central1-argon-fx-383518.cloudfunctions.net/teamQuickView";

    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ PartnerID: TempPid }),
    })
      .then((response) => response.json())
      .then((Data) => {
        console.log("TQV: ", Data);
        setData(Data);
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };

  const handleChange = (event) => {
    // let food = event.target.value;
    // setPeriod(food);
    // console.log("test dashboard: ", period);
  };

  const columns = [
    {
      field: "AgentName",
      headerName: "Agent",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { AgentName, AgentID } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Link
            to="/TeamExpView"
            state={{
              AgentID: AgentID,
            }}
          >
            {AgentName}
          </Link>
        );
        return jsxBlock;
      },
    },
    {
      field: "Units",
      headerName: "Units",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { Units } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              Units == "Higher"
                ? colors.greenAccent[600]
                : colors.redAccent[600]
            }
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "SpectrumChargebacks",
      headerName: "Spect CBs",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: {} }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={"lightgray"}
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "ChargebackPercentage",
      headerName: "Chargebacks",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { ChargebackPercentage } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              ChargebackPercentage < 15
                ? colors.greenAccent[600]
                : colors.redAccent[600]
            }
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "SalesCanceledPercentage",
      headerName: "Canceled",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { SalesCanceledPercentage } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              SalesCanceledPercentage > 10
                ? colors.redAccent[600]
                : colors.greenAccent[600]
            }
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "GigPercentage",
      headerName: "Gig",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { GigPercentage } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              GigPercentage < 18
                ? colors.redAccent[600]
                : colors.greenAccent[600]
            }
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "VideoePercentage",
      headerName: "Video",
      flex: 1,
      renderCell: ({ row: { VideoePercentage } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              VideoePercentage > 0.1
                ? colors.redAccent[600]
                : colors.greenAccent[600]
            }
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "TrainingComplete",
      headerName: "Training",
      flex: 1,
      renderCell: ({ row: { TrainingComplete } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              TrainingComplete == "Yes"
                ? colors.greenAccent[600]
                : colors.redAccent[600]
            }
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "Trainingsx",
      headerName: "VPN",
      flex: 1,
      renderCell: ({ row: {} }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={"#70d8bd"}
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "Trainingsxx",
      headerName: "Remote",
      flex: 1,
      renderCell: ({ row: {} }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={"#70d8bd"}
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "Status",
      headerName: "Status",
      flex: 1,
    },
  ];

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="mb-0"
      >
        <Header title="Team Quick View" subtitle="Agent Overview" />

        <Box>
          <FormControl fullWidth sx={{ mt: "-25px", mb: "0px" }} size="small">
            <InputLabel id="demo-simple-select-label">Period</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={period}
              label="Age"
              onChange={handleChange}
            >
              <MenuItem value={"all"}>All</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box
        m="10px 0 40px 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={data}
          columns={columns}
          getRowId={(row) => row.AgentID}
        />
      </Box>
    </Box>
  );
};

export default Team;
