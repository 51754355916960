import React, { useEffect } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import Iframe from "react-iframe";

const InternetandSpectrumOne = () => {
  useEffect(() => {}, []);
  return (
    <Box mt={"13px"}>
      <iframe
        src="https://docs.google.com/presentation/d/e/2PACX-1vTYQN3Qm7ahg2UXMsdbi-S5GYk7Xpoz2nzh89mkruw-ezpg1oVtbdQiLiJsjMgFkpZojQU-ONBf4OrE/embed?start=false&loop=false&delayms=3000"
        frameborder="0"
        width="1280px"
        height="749px"
        allowfullscreen="true"
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
      ></iframe>
    </Box>
  );
};

export default InternetandSpectrumOne;
