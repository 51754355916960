import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import { mockDataTeam } from "../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import { realData } from "../data/realData";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const Team = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState([]);
  const notify = (x) => toast(x);
  const notifyError = (x) => toast.error(x);

  let Agent = props.agent;
  const excludedIds = ["Pending", "Unknown", null];
  useEffect(() => {
    console.log("useeffect", data.length);

    if (Agent && Agent.PartnerID && data.length == 0) {
      console.log("if statement", Agent.PartnerID);
      fetchDataFromDatabase();
    }
  }, [Agent]);

  const fetchDataFromDatabase = async () => {
    let url = "";
    console.log("fetchDataFromDatabase: ", Agent.PartnerID);
    let VTL = { VTL: Agent.PartnerID };
    console.log("H: ", Agent.Hierarchy);
    if (Agent.Hierarchy == "Master") {
      url = "https://us-central1-argon-fx-383518.cloudfunctions.net/queryDB";
    } else if (Agent.Hierarchy == "Owner" || "Sub Distributor" || "Manager") {
      url =
        "https://us-central1-argon-fx-383518.cloudfunctions.net/getAgentsByVTL";
    }

    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(VTL),
    })
      .then((response) => response.json())
      .then((data) => {
        // Do something with the data, e.g. update component state
        setData([]);
        setData(data);
        console.log(data);
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };

  // toggle status in database based on AgentID
  const toggleAgent = async (AgentID, Status) => {
    let payload = { AgentID: AgentID, Status: Status };
    console.log(payload);

    try {
      const response = await fetch(
        "https://us-central1-argon-fx-383518.cloudfunctions.net/toggleAgent",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      // Check if the response is okay
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Parse the response as JSON
      const data = await response.json();

      // Use the message from the parsed data
      notify(data.message);
      console.log("Response: ", data);

      // Refresh or update the component state
      fetchDataFromDatabase();
    } catch (error) {
      // Handle error
      notifyError("Error: " + error.message + " [LE004]");
    }
  };

  const columns = [
    {
      field: "PartnerID",
      headerName: "Partner ID",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "CompanyName",
      headerName: "Company Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    { field: "AgentID", headerName: "Agent ID" },
    {
      field: "FirstName",
      headerName: "first Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "LastName",
      headerName: "last Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "MobileNumber",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "Email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "accessLevel",
      headerName: "Access Level",
      flex: 1,
      renderCell: ({ row: { Status, AgentID } }) => {
        let jsxBlock = null;
        if (Agent.Hierarchy === "Master" || Agent.Hierarchy === "Owner") {
          jsxBlock = (
            <Box
              width="60%"
              m="0 auto"
              p="5px"
              display="flex"
              justifyContent="center"
              onClick={() => {
                toggleAgent(AgentID, Status);
              }}
              backgroundColor={
                Status === "Active"
                  ? colors.greenAccent[600]
                  : Status === "Disabled"
                  ? colors.redAccent[600]
                  : colors.greenAccent[700]
                  ? "#ffd952" // Use yellowAccent for Limited status
                  : colors.greenAccent[700]
              }
              borderRadius="4px"
            >
              {Status === "admin" && <AdminPanelSettingsOutlinedIcon />}
              {Status === "manager" && <SecurityOutlinedIcon />}
              {Status === "user" && <LockOpenOutlinedIcon />}
              <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                {Status}
              </Typography>
            </Box>
          );
        }
        return jsxBlock;
      },
    },
  ];

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="mb-0"
      >
        <Header title="Team" subtitle="Manage team" />

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            component={Link}
            to="/agentRegistration"
          >
            <PersonAddAltIcon sx={{ mr: "10px" }} />
            Add Agent
          </Button>
        </Box>
      </Box>
      <Box
        m="10px 0 40px 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={data.filter((row) => !excludedIds.includes(row.AgentID))}
          columns={columns}
          getRowId={(row) => row.AgentID}
        />
      </Box>
    </Box>
  );
};

export default Team;
