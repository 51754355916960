import { Box, useTheme } from "@mui/material";
import Header from "../../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../../theme";
import { useEffect } from "react";

const FAQ = (props) => {
  const Agent = props.agent;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {}, []);

  return (
    <Box m="20px">
      <Header title="FAQ" subtitle="Frequently Asked Questions" />
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Trouble signing in?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Aside from the most obvious problems like wrong Email, or Password,
            Some problems you may encounter could be:
            <ul>
              <li>Invalid IP</li>
              <li>Invalid Fingerprint</li>
            </ul>
            You can identify your error by the error message and look below for
            soloutions to that problem.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Invalid IP
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Your IP address is invalid. Make sure you are using the correct VPN
            and Whitelisted Static IP.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Invalid Fingerprint
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Your Fingerprint is invalid, This means you are using a diferent
            computer / web browser combination than last time. To fix this
            return to your last computer / browser configuration. If you are
            unable to do this and are still having problems contact your Vomtel
            Account Manager for further help.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FAQ;
