import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useLocation, useNavigate } from "react-router-dom";

const ServiceProviders = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [providers, setProviders] = useState({});

  const location = useLocation();
  const navigate = useNavigate();
  const { zipCode, URL, values } = location.state || {};

  useEffect(() => {
    fetchServiceProviders(zipCode);
  }, [zipCode]);

  const fetchServiceProviders = async (zipCode) => {
    let Payload = { ZipCode: zipCode };
    try {
      const response = await fetch(
        `https://us-central1-argon-fx-383518.cloudfunctions.net/ZipServiceProviders`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(Payload),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Service Providers: ", data);
      setProviders(data[0] || {});
    } catch (error) {
      console.error("Error fetching service providers:", error);
    }
  };

  const handleProviders = (provider) => {
    console.log(provider);
    switch (provider) {
      case "Spectrum":
        navigate("/partnerRegisterSale", { state: { values: values } });
        window.open(URL, "_blank", "noopener,noreferrer");
        break;
      // You can add more cases here for other providers if needed
      default:
        console.log("Provider not handled:", provider);
    }
  };

  // Check if the providers object is empty
  const isProvidersEmpty =
    Object.keys(providers).length === 0 || !providers.ZipCode;

  return (
    <Box
      className="m-5"
      display="grid"
      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
    >
      <Header title="Select Provider" sx={{ gridColumn: "span 4" }} />

      {isProvidersEmpty ? (
        <Box sx={{ gridColumn: "span 4", textAlign: "center", mt: 4 }}>
          <Typography
            color={colors.greenAccent[500]}
            variant="h3"
            fontWeight="bold"
          >
            Loading..
          </Typography>
        </Box>
      ) : (
        <>
          <Box
            sx={{ gridColumn: "span 4" }}
            className="mb-4"
            display="grid"
            gridTemplateColumns="repeat(4, minmax(0, auto))"
            columnGap="20px"
            rowGap="20px"
            justifyContent="start"
          >
            <Box sx={{ gridColumn: "span 4" }} className="mb-1">
              <Typography color={colors.greenAccent[500]} variant="h3">
                Internet
              </Typography>
            </Box>
            {[
              "Spectrum",
              "Xfinity",
              "Optimum",
              "Cox",
              "Frontier",
              "Windstream",
              "Earthlink",
              "Centurylink",
              "Brightspeed",
              "Viasat",
            ].map(
              (provider) =>
                providers[provider] === "Yes" && (
                  <Box
                    onClick={() => handleProviders(provider)}
                    key={provider}
                    sx={{
                      gridColumn: "span 1",
                      height: "auto",
                      width: "200px",
                    }}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        `assets/Logos/${provider} Button${
                          provider === "Spectrum" ? "" : " G"
                        }.png`
                      }
                      alt={`${provider} Logo`}
                    />
                  </Box>
                )
            )}
          </Box>
          <Box
            sx={{ gridColumn: "span 4" }}
            className="mb-4"
            display="grid"
            gridTemplateColumns="repeat(4, minmax(0, auto))"
            columnGap="20px"
            rowGap="20px"
            justifyContent="start"
          >
            <Box sx={{ gridColumn: "span 4" }} className="mt-4 mb-1">
              <Typography color={colors.greenAccent[500]} variant="h3">
                Television
              </Typography>
            </Box>
            {["DirectTV"].map(
              (provider) =>
                providers[provider] === "Yes" && (
                  <Box
                    onClick={() => handleProviders(provider)}
                    key={provider}
                    sx={{
                      gridColumn: "span 1",
                      height: "auto",
                      width: "200px",
                    }}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        `assets/Logos/${provider} Button G.png`
                      }
                      alt={`${provider} Logo`}
                    />
                  </Box>
                )
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default ServiceProviders;
