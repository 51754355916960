import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import { mockDataTeam } from "../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import { realData } from "../data/realData";
import { Link } from "react-router-dom";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const Team = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState([]);
  const [period, setPeriod] = useState("all");
  const [agentObjects, setAgentObjects] = useState([]);
  const [partnerArray, setPartnerArray] = useState([]);
  const [partnerObject, setPartnerObject] = useState({});

  let Agent = props.agent;

  useEffect(() => {
    if (Agent && Agent.PartnerID && data.length == 0) {
      console.log("if statement", Agent.PartnerID);
      // Start call chain
      PartnerDetails();
      // fetchDataFromDatabase();
    }
  }, [Agent, period]);

  // Fetch Partner Details for Marketing
  const PartnerDetails = async () => {
    let url =
      "https://us-central1-argon-fx-383518.cloudfunctions.net/getAllPartnerDetails";

    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((Data) => {
        console.log("PartnerDetails: ", Data);
        PartnerAlias(Data);
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };
  // Fetch Partner Aliases
  const PartnerAlias = async (PartnerDetails) => {
    let url = "https://us-central1-argon-fx-383518.cloudfunctions.net/getAlias";

    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((Data) => {
        console.log("Alias: ", Data);
        fetchDataFromDatabase(Data, PartnerDetails);
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };

  // Fetch all agents under the same VTL
  const fetchDataFromDatabase = async (Alias, PartnerDetails) => {
    let url = "";
    let AgentIDs = [];
    let PartnerObjects = {};
    let AgentObjects = [];

    url = "https://us-central1-argon-fx-383518.cloudfunctions.net/queryDB";

    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((Data) => {
        // Do something with the data, e.g. update component state

        // Create Agent Objects for each agent
        Data.forEach((item) => {
          AgentIDs.push(item.AgentID);
          // console.log("wtf is item: ", item);

          let found = Alias.findIndex((element) => {
            // console.log("test: ", element.AgentID.toUpperCase());
            return (
              element.PartnerID.toUpperCase() == item.PartnerID.toUpperCase()
            );
          });

          if (found > -1) {
            let marketingCompliance;
            let callCalibration;
            let foundPartner = PartnerDetails.findIndex((element) => {
              return (
                element.PartnerID.toUpperCase() ==
                Alias[found].SpectrumPartnerID.toUpperCase()
              );
            });
            if (foundPartner >= 0) {
              marketingCompliance =
                PartnerDetails[foundPartner].MarketingCompliance;
              callCalibration = PartnerDetails[foundPartner].CallCalibrations;
            }

            AgentObjects.push({
              PartnerName: Alias[found].SpectrumName,
              VTL: Alias[found].SpectrumPartnerID,
              AgentID: item.AgentID,
            });

            if (PartnerObjects[Alias[found].SpectrumPartnerID]) {
              PartnerObjects[Alias[found].SpectrumPartnerID].Agents.push(
                item.AgentID
              );
              // PartnerObjects[Alias[found].SpectrumPartnerID].AgentObjs.push({
              //   AgentID: item.AgentID,
              //   Name: item.FirstName + " " + item.LastName,
              // });
            } else {
              PartnerObjects[Alias[found].SpectrumPartnerID] = {
                PartnerName: Alias[found].SpectrumName,
                PartnerID: Alias[found].SpectrumPartnerID,
                Agents: [item.AgentID],
                MarketCompliance: marketingCompliance,
                CallCalibration: callCalibration,
                Internet: 0,
                Video: 0,
                Mobile: 0,
                Voice: 0,
                Chargebacks: 0,
                TotalUnits: 0,
                Canceled: 0,
                All: 0,
                Gig: 0,
                CBPerc: 0,
                GigPerc: 0,
                VideoPerc: 0,
                Status: item.Status,
              };
            }
          } else {
            let marketingCompliance;
            let callCalibration;
            let foundPartner = PartnerDetails.findIndex((element) => {
              return (
                element.PartnerID.toUpperCase() == item.PartnerID.toUpperCase()
              );
            });
            if (foundPartner > 0) {
              marketingCompliance =
                PartnerDetails[foundPartner].MarketingCompliance;
              callCalibration = PartnerDetails[foundPartner].CallCalibrations;
            }
            AgentObjects.push({
              PartnerName: item.CompanyName,
              VTL: item.PartnerID,
              AgentID: item.AgentID,
            });

            if (PartnerObjects[item.PartnerID]) {
              PartnerObjects[item.PartnerID].Agents.push(item.AgentID);
            } else {
              PartnerObjects[item.PartnerID] = {
                PartnerName: item.CompanyName,
                PartnerID: item.PartnerID,
                Agents: [item.AgentID],
                MarketCompliance: marketingCompliance,
                CallCalibration: callCalibration,
                Internet: 0,
                Video: 0,
                Mobile: 0,
                Voice: 0,
                Chargebacks: 0,
                TotalUnits: 0,
                Canceled: 0,
                All: 0,
                Gig: 0,
                CBPerc: 0,
                GigPerc: 0,
                VideoPerc: 0,
                Status: item.Status,
              };
            }
          }
        });
        fetchChargebacksByMultipleVTLs(AgentIDs, AgentObjects, PartnerObjects);
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };

  // Fetch all agents under the same VTL
  const fetchChargebacksByMultipleVTLs = async (
    AgentIDs,
    AgentObjects,
    PartnerObjects
  ) => {
    let url = "";
    let Payload = { AgentIDs: AgentIDs };
    let finalArray = [];

    url =
      "https://us-central1-argon-fx-383518.cloudfunctions.net/getChargebacksByMultipleVTLs";

    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(Payload),
    })
      .then((response) => response.json())
      .then((Data) => {
        // Do something with the data, e.g. update component state
        console.log("TEAM DATA: ", Data);
        // Iterate through response and build agent data
        Data.forEach((item) => {
          let found = AgentObjects.findIndex((element) => {
            // console.log("test: ", element.AgentID.toUpperCase());
            return element.AgentID.toUpperCase() == item.Agent.toUpperCase();
          });
          let Partner = AgentObjects[found].VTL;

          // Data Churning Machine
          if (found != -1) {
            // console.log("FOUND");
            let fiscalCaps = item.FiscalMonth.toUpperCase();
            let periodCaps = period.toUpperCase();
            if (
              period == "all" ||
              (fiscalCaps.search(periodCaps) >= 0 && period != "")
            ) {
              console.log("FOUND");

              // Get all Sales Connected
              if (item.SalesConnects == 1) {
                PartnerObjects[Partner].All++;
              }
              // Get chargebacks
              if (item.Disconnects == 1 && item.Survival90Day == 0) {
                PartnerObjects[Partner].Chargebacks++;
              }
              // Get canceled
              if (item.SalesCanceled == 1) {
                PartnerObjects[Partner].Canceled++;
              }
              // Get respective sales
              if (
                item.PSU == "Internet" &&
                item.InternetTierTimeOfConnect == "SPP Gig"
              ) {
                PartnerObjects[Partner].Internet++;
                PartnerObjects[Partner].Gig++;
              } else if (item.PSU == "Internet") {
                PartnerObjects[Partner].Internet++;
              }
              if (item.PSU == "Video") {
                PartnerObjects[Partner].Video++;
              }
              if (item.PSU == "Voice") {
                PartnerObjects[Partner].Voice++;
              }
              if (item.PSU == "Mobile") {
                PartnerObjects[Partner].Mobile++;
              }
            }
            PartnerObjects[Partner].CBPerc = (
              (PartnerObjects[Partner].Chargebacks /
                PartnerObjects[Partner].All) *
              100
            ).toFixed(2);

            PartnerObjects[Partner].GigPerc = (
              (PartnerObjects[Partner].Gig / PartnerObjects[Partner].Internet) *
              100
            ).toFixed(2);

            PartnerObjects[Partner].CancelPerc = (
              (PartnerObjects[Partner].Canceled / PartnerObjects[Partner].All) *
              100
            ).toFixed(2);

            PartnerObjects[Partner].VideoPerc = (
              (PartnerObjects[Partner].Video / PartnerObjects[Partner].All) *
              100
            ).toFixed(2);
          }
        }); // end of foreach
        // Convert PartnerObject into an Array
        Object.keys(PartnerObjects).map((key) =>
          finalArray.push(PartnerObjects[key])
        );
        setAgentObjects(AgentObjects);
        setPartnerArray(finalArray);
        setPartnerObject(PartnerObjects);
        console.log("PartnerObjects: ", PartnerObjects);
        console.log("FINAL: ", finalArray);
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };

  const handleChange = (event) => {
    let food = event.target.value;
    setPeriod(food);
    console.log("test dashboard: ", period);
  };

  const columns = [
    {
      field: "Partner Name",
      headerName: "Partner ID",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { PartnerName, PartnerID } }) => {
        // console.log("PartnerObject: ", partnerObject[PartnerID]);
        let jsxBlock = null;
        jsxBlock = (
          <Link
            to="/spectrumPartnerPortfolio"
            state={{
              PartnerObject: partnerObject[PartnerID],
              PartnerID: PartnerID,
            }}
          >
            {PartnerName}
          </Link>
        );
        return jsxBlock;
      },
    },
    {
      field: "All",
      headerName: "Total Units",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: {} }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={"lightgray"}
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "SpectrumChargebacks",
      headerName: "Spectrum CBs",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: {} }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={"lightgray"}
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "CBPerc",
      headerName: "Chargebacks",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { CBPerc } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              CBPerc > 15 ? colors.greenAccent[600] : colors.redAccent[600]
            }
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "CancelPerc",
      headerName: "Canceled",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { CancelPerc } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              CancelPerc > 10 ? colors.redAccent[600] : colors.greenAccent[600]
            }
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "GigPerc",
      headerName: "Gig",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { GigPerc } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              GigPerc < 18 ? colors.redAccent[600] : colors.greenAccent[600]
            }
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "VideoPerc",
      headerName: "Video",
      flex: 1,
      renderCell: ({ row: { VideoPerc } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              VideoPerc < 15 ? colors.redAccent[600] : colors.greenAccent[600]
            }
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "MarketCompliance",
      headerName: "Market Comp",
      flex: 1,
      renderCell: ({ row: { MarketCompliance } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              MarketCompliance == "Compliant"
                ? colors.greenAccent[600]
                : colors.redAccent[600]
            }
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "CallCalibration",
      headerName: "Call Calibr",
      flex: 1,
      renderCell: ({ row: { CallCalibration } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              CallCalibration == "Compliant"
                ? colors.greenAccent[600]
                : colors.redAccent[600]
            }
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "Trainings",
      headerName: "Trainings",
      flex: 1,
      renderCell: ({ row: {} }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor="#f3af2fa8"
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "Trainingsx",
      headerName: "VPN",
      flex: 1,
      renderCell: ({ row: {} }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={"lightgray"}
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "Trainingsxx",
      headerName: "Remote",
      flex: 1,
      renderCell: ({ row: {} }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={"lightgray"}
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "Status",
      headerName: "Access Level",
      flex: 1,
    },
  ];

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="mb-0"
      >
        <Header title="Spectrum Quick View" subtitle="Partner Overview" />

        <Box>
          <FormControl fullWidth sx={{ mt: "-25px", mb: "0px" }} size="small">
            <InputLabel id="demo-simple-select-label">Period</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={period}
              label="Age"
              onChange={handleChange}
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"november"}>November</MenuItem>
              <MenuItem value={"december"}>December</MenuItem>
              <MenuItem value={"january"}>January</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box
        m="10px 0 40px 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={partnerArray}
          columns={columns}
          getRowId={(row) => row.PartnerID}
        />
      </Box>
    </Box>
  );
};

export default Team;
