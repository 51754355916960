export const realData = [
  {
    company_name: "Vomtel",
    store_id: "VTL1000",
    agent_id: "24154",
    buy_flow_id: "24154",
    first_name: "Jorgina",
    last_name: "Munoz",
    email: "jorgina@vomtel.com",
    mobile_number: "9092296749",
    street_address: "",
    address_line_2: "",
    city: "",
    state: "",
    zip: "",
    vomtel_email: "",
    sales_id: "24154",
    account_manager: "AM002",
    manager_name: "Joe",
    vom_logins: "",
    cas_logins: "",
    emailed: "",
    mobile_training: "",
    core_products: "",
    portal_training: "",
    marketing_training: "",
    sales_101: "\r",
  },
  //   {
  //     company_name: "Vomtel",
  //     store_id: "VTL1000",
  //     agent_id: "AGT00010",
  //     buy_flow_id: "AGT00010",
  //     first_name: "Julio (Camilo)",
  //     last_name: "Castro (Salazar)",
  //     email: "julio1@vomtel.com",
  //     mobile_number: "9092296749",
  //     street_address: "",
  //     address_line_2: "",
  //     city: "",
  //     state: "",
  //     zip: "",
  //     vomtel_email: "vomtel@vomtel.site",
  //     sales_id: "24154",
  //     account_manager: "AM002",
  //     manager_name: "Joe",
  //     vom_logins: "Yes",
  //     cas_logins: "",
  //     emailed: "",
  //     mobile_training: "",
  //     core_products: "",
  //     portal_training: "",
  //     marketing_training: "",
  //     sales_101: "\r",
  //   },
  //   {
  //     company_name: "Humantelecom",
  //     store_id: "VTL1003",
  //     agent_id: "AGT00020",
  //     buy_flow_id: "AGT00020",
  //     first_name: "Hugo",
  //     last_name: "De la Rosa",
  //     email: "hugod@humantelecom.com",
  //     mobile_number: "9095320206",
  //     street_address: "251 W. Foothill Blvd",
  //     address_line_2: "",
  //     city: "Rialto",
  //     state: "CA",
  //     zip: "92376",
  //     vomtel_email: "humantelecom@vomtel.site",
  //     sales_id: "64202",
  //     account_manager: "AM003",
  //     manager_name: "Luis",
  //     vom_logins: "Yes",
  //     cas_logins: "",
  //     emailed: "",
  //     mobile_training: "",
  //     core_products: "",
  //     portal_training: "",
  //     marketing_training: "",
  //     sales_101: "\r",
  //   },
  //   {
  //     company_name: "BR Communication LLC",
  //     store_id: "VTL1004",
  //     agent_id: "AGT00021",
  //     buy_flow_id: "AGT00021",
  //     first_name: "Bimlesh",
  //     last_name: "Karn",
  //     email: "karnbimlesh@gmail.com",
  //     mobile_number: "6153977072",
  //     street_address: "865 Bellevue RD",
  //     address_line_2: "H 19",
  //     city: "Nashville",
  //     state: "Tennessee",
  //     zip: "37221",
  //     vomtel_email: "brcommunication@vomtel.site",
  //     sales_id: "78229",
  //     account_manager: "AM017",
  //     manager_name: "Juan",
  //     vom_logins: "Yes",
  //     cas_logins: "",
  //     emailed: "",
  //     mobile_training: "Yes",
  //     core_products: "Yes",
  //     portal_training: "Yes",
  //     marketing_training: "",
  //     sales_101: "\r",
  //   },
  //   {
  //     company_name: "JS Marketing Solution LLC (Teletalk Services) RA",
  //     store_id: "VTL1005",
  //     agent_id: "AGT00022",
  //     buy_flow_id: "AGT00022",
  //     first_name: "Jamal",
  //     last_name: "Mohammad",
  //     email: "jsmarketingsolution9@gmail.com",
  //     mobile_number: "7739408865",
  //     street_address: "5506 LINCOLN AVE",
  //     address_line_2: "UNIT 523",
  //     city: "Morton Grove",
  //     state: "IL",
  //     zip: "60053",
  //     vomtel_email: "teletalkservices@vomtel.site",
  //     sales_id: "78270",
  //     account_manager: "AM002",
  //     manager_name: "Joe",
  //     vom_logins: "Yes",
  //     cas_logins: "",
  //     emailed: "Y",
  //     mobile_training: "Yes",
  //     core_products: "",
  //     portal_training: "",
  //     marketing_training: "",
  //     sales_101: "Yes\r",
  //   },
  //   {
  //     company_name: "Umbrella Technolosutions INC",
  //     store_id: "VTL1006",
  //     agent_id: "AGT00023",
  //     buy_flow_id: "AGT00023",
  //     first_name: "Joe",
  //     last_name: "Phillips",
  //     email: "joe.phillips@umbrellatechosolutions.com",
  //     mobile_number: "3025699245",
  //     street_address: "256 CHAPMAN ROAD, NEWARK, 19702",
  //     address_line_2: "",
  //     city: "NEWARK",
  //     state: "DE",
  //     zip: "92504",
  //     vomtel_email: "umbrellatechnolosutions@vomtel.site",
  //     sales_id: "78274",
  //     account_manager: "AM017",
  //     manager_name: "Juan",
  //     vom_logins: "Yes",
  //     cas_logins: "",
  //     emailed: "Y",
  //     mobile_training: "Yes",
  //     core_products: "",
  //     portal_training: "",
  //     marketing_training: "",
  //     sales_101: "\r",
  //   },
  //   {
  //     company_name: "BR Communication LLC",
  //     store_id: "VTL1004",
  //     agent_id: "AGT00025",
  //     buy_flow_id: "AGT00025",
  //     first_name: "Bimlesh",
  //     last_name: "Karn",
  //     email: "karnbimlesh@gmail.com1",
  //     mobile_number: "",
  //     street_address: "",
  //     address_line_2: "",
  //     city: "",
  //     state: "",
  //     zip: "",
  //     vomtel_email: "",
  //     sales_id: "78229",
  //     account_manager: "AM017",
  //     manager_name: "Juan",
  //     vom_logins: "",
  //     cas_logins: "",
  //     emailed: "",
  //     mobile_training: "",
  //     core_products: "",
  //     portal_training: "",
  //     marketing_training: "",
  //     sales_101: "\r",
  //   },
  //   {
  //     company_name: "Cascadanet",
  //     store_id: "VTL1008",
  //     agent_id: "AGT00026",
  //     buy_flow_id: "AGT00026",
  //     first_name: "Jose",
  //     last_name: "Hernandez",
  //     email: "tony@cascadanet.com2",
  //     mobile_number: "3212310914",
  //     street_address: "5703 RED BUG LAKE RD STE 246",
  //     address_line_2: "cascadanet",
  //     city: "Winter Springs",
  //     state: "FL",
  //     zip: "32765",
  //     vomtel_email: "",
  //     sales_id: "67264",
  //     account_manager: "AM014",
  //     manager_name: "Natalia",
  //     vom_logins: "",
  //     cas_logins: "",
  //     emailed: "",
  //     mobile_training: "",
  //     core_products: "",
  //     portal_training: "",
  //     marketing_training: "",
  //     sales_101: "\r",
  //   },
  //   {
  //     company_name: "Multi-comm",
  //     store_id: "VTL1008",
  //     agent_id: "AGT00027",
  //     buy_flow_id: "AGT00027",
  //     first_name: "Neivy",
  //     last_name: "Melgar",
  //     email: "nmelgar@multi-comm.us",
  //     mobile_number: "",
  //     street_address: "",
  //     address_line_2: "",
  //     city: "",
  //     state: "",
  //     zip: "",
  //     vomtel_email: "multicomm@vomtel.site",
  //     sales_id: "67264",
  //     account_manager: "AM014",
  //     manager_name: "Natalia",
  //     vom_logins: "",
  //     cas_logins: "",
  //     emailed: "",
  //     mobile_training: "",
  //     core_products: "",
  //     portal_training: "",
  //     marketing_training: "",
  //     sales_101: "\r",
  //   },
  //   {
  //     company_name: "EKP Legacy Group",
  //     store_id: "VTL1009",
  //     agent_id: "AGT00028",
  //     buy_flow_id: "AGT00028",
  //     first_name: "Stephen",
  //     last_name: "Arroyo",
  //     email: "ekplegacygroup1@gmail.com",
  //     mobile_number: "8178519822",
  //     street_address: "6600 Chase Oaks Blvd,",
  //     address_line_2: "Suite 150",
  //     city: "Plano",
  //     state: "Texas",
  //     zip: "75044",
  //     vomtel_email: "ekplegacygroup@vomtel.site",
  //     sales_id: "72383",
  //     account_manager: "AM017",
  //     manager_name: "Juan",
  //     vom_logins: "Yes",
  //     cas_logins: "",
  //     emailed: "",
  //     mobile_training: "",
  //     core_products: "",
  //     portal_training: "",
  //     marketing_training: "",
  //     sales_101: "\r",
  //   },
];
// CREATE TABLE sales_data (
//     company_name varchar(255),
//     store_id varchar(255),
//     agent_id varchar(255),
//     buy_flow_id varchar(255),
//     first_name varchar(255),
//     last_name varchar(255),
//     email varchar(255),
//     mobile_number varchar(255),
//     street_address varchar(255),
//     address_line_2 varchar(255),
//     city varchar(255),
//     state varchar(255),
//     zip varchar(255),
//     vomtel_email varchar(255),
//     sales_id varchar(255),
//     account_manager varchar(255),
//     manager_name varchar(255),
//     vom_logins varchar(255),
//     cas_logins varchar(255),
//     emailed varchar(255),
//     mobile_training varchar(255),
//     core_products varchar(255),
//     portal_training varchar(255),
//     marketing_training varchar(255),
//     sales_101 varchar(255)
// );
