import { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../../theme";
// import { mockBarData as data } from "../../data/mockData";

const BarChart = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isDashboard = props.isDashboard;
  const Agent = props.agent;
  const Chargeback = props.chargeback;
  const [m1, setM1] = useState();
  const [m2, setM2] = useState();
  const [m3, setM3] = useState();
  const [m4, setM4] = useState();
  const [barData, setBarData] = useState([
    {
      month: "Jan",
      "hot dog": 137,
      "hot dogColor": "hsl(229, 70%, 50%)",
      burger: 96,
      burgerColor: "hsl(296, 70%, 50%)",
      kebab: 72,
      kebabColor: "hsl(97, 70%, 50%)",
      donut: 140,
      donutColor: "hsl(340, 70%, 50%)",
    },
    {
      month: "Feb",
      "hot dog": 55,
      "hot dogColor": "hsl(307, 70%, 50%)",
      burger: 28,
      burgerColor: "hsl(111, 70%, 50%)",
      kebab: 58,
      kebabColor: "hsl(273, 70%, 50%)",
      donut: 29,
      donutColor: "hsl(275, 70%, 50%)",
    },
    {
      month: "Mar",
      "hot dog": 109,
      "hot dogColor": "hsl(72, 70%, 50%)",
      burger: 23,
      burgerColor: "hsl(96, 70%, 50%)",
      kebab: 34,
      kebabColor: "hsl(106, 70%, 50%)",
      donut: 152,
      donutColor: "hsl(256, 70%, 50%)",
    },
  ]);

  useEffect(() => {
    if (Agent && Agent.PartnerID) {
      dataChurningMachine();
    }
  }, [Chargeback]);

  const dataChurningMachine = () => {
    let m1 = {
      internet: 0,
      video: 0,
      voice: 0,
      mobile: 0,
    };
    let m2 = {
      internet: 0,
      video: 0,
      voice: 0,
      mobile: 0,
    };
    let m3 = {
      internet: 0,
      video: 0,
      voice: 0,
      mobile: 0,
    };
    let m4 = {
      internet: 0,
      video: 0,
      voice: 0,
      mobile: 0,
    };

    // let m1internet = 0;
    // let m1video = 0;
    // let m1voice = 0;
    // let m1mobile = 0;

    // let m2internet = 0;
    // let m2video = 0;
    // let m2voice = 0;
    // let m2mobile = 0;

    // let m3internet = 0;
    // let m3video = 0;
    // let m3voice = 0;
    // let m3mobile = 0;

    // let m4internet = 0;
    // let m4video = 0;
    // let m4voice = 0;
    // let m4mobile = 0;

    Chargeback.forEach((item) => {
      if (
        item.Disconnects == 0 &&
        item.SalesPending == 0 &&
        item.SalesCanceled == 0
      ) {
        //  MONTH ONE
        if (item.DateConnect.slice(0, 2) == "10") {
          if (item.PSU == "Internet") {
            m1.internet++;
          }
          if (item.PSU == "Video") {
            m1.video++;
          }
          if (item.PSU == "Voice") {
            m1.voice++;
          }
          if (item.PSU == "Mobile") {
            m1.mobile++;
          }
        }

        //  MONTH TWO
        if (item.DateConnect.slice(0, 2) == "11") {
          if (item.PSU == "Internet") {
            m2.internet++;
          }
          if (item.PSU == "Video") {
            m2.video++;
          }
          if (item.PSU == "Voice") {
            m2.voice++;
          }
          if (item.PSU == "Mobile") {
            m2.mobile++;
          }
        }

        //  MONTH THREE
        if (item.DateConnect.slice(0, 2) == "12") {
          if (item.PSU == "Internet") {
            m3.internet++;
          }
          if (item.PSU == "Video") {
            m3.video++;
          }
          if (item.PSU == "Voice") {
            m3.voice++;
          }
          if (item.PSU == "Mobile") {
            m3.mobile++;
          }
        }

        //  MONTH FOUR
        if (item.DateConnect.slice(0, 2) == "1/") {
          if (item.PSU == "Internet") {
            m4.internet++;
          }
          if (item.PSU == "Video") {
            m4.video++;
          }
          if (item.PSU == "Voice") {
            m4.voice++;
          }
          if (item.PSU == "Mobile") {
            m4.mobile++;
          }
        }
      }
    });
    setBarData([
      {
        month: "Oct",
        Internet: m1.internet,
        InternetColor: "hsl(229, 70%, 50%)",
        Voice: m1.voice,
        VoiceColor: "hsl(296, 70%, 50%)",
        Video: m1.video,
        VideoColor: "hsl(97, 70%, 50%)",
        Mobile: m1.mobile,
        MobileColor: "hsl(340, 70%, 50%)",
      },
      {
        month: "Nov",
        Internet: m2.internet,
        InternetColor: "hsl(229, 70%, 50%)",
        Voice: m2.voice,
        VoiceColor: "hsl(296, 70%, 50%)",
        Video: m2.video,
        VideoColor: "hsl(97, 70%, 50%)",
        Mobile: m2.mobile,
        MobileColor: "hsl(340, 70%, 50%)",
      },
      {
        month: "Dec",
        Internet: m3.internet,
        InternetColor: "hsl(229, 70%, 50%)",
        Voice: m3.voice,
        VoiceColor: "hsl(296, 70%, 50%)",
        Video: m3.video,
        VideoColor: "hsl(97, 70%, 50%)",
        Mobile: m3.mobile,
        MobileColor: "hsl(340, 70%, 50%)",
      },
      {
        month: "Jan",
        Internet: m4.internet,
        InternetColor: "hsl(229, 70%, 50%)",
        Voice: m4.voice,
        VoiceColor: "hsl(296, 70%, 50%)",
        Video: m4.video,
        VideoColor: "hsl(97, 70%, 50%)",
        Mobile: m4.mobile,
        MobileColor: "hsl(340, 70%, 50%)",
      },
    ]);
  };

  return (
    <ResponsiveBar
      data={barData}
      theme={{
        // added
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
      }}
      keys={["Internet", "Video", "Voice", "Mobile"]}
      indexBy="month"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "nivo" }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      borderColor={{
        from: "color",
        modifiers: [["darker", "1.6"]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "country", // changed
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "food", // changed
        legendPosition: "middle",
        legendOffset: -40,
      }}
      enableLabel={true}
      labelSkipWidth={12}
      labelSkipHeight={0}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      barAriaLabel={function (e) {
        return e.id + ": " + e.formattedValue + " in country: " + e.indexValue;
      }}
    />
  );
};

export default BarChart;
