// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCyTVsVD48Iwo8eYF8IZuyBJKEac4NTiic",
  authDomain: "argon-fx-383518.firebaseapp.com",
  projectId: "argon-fx-383518",
  storageBucket: "argon-fx-383518.appspot.com",
  messagingSenderId: "893552724008",
  appId: "1:893552724008:web:3a52afa2da47f8736fcd1d",
  measurementId: "G-3PV41KPT10",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

export default app;
