import React from "react";
import Header from "../components/Header";
import { Box, Button, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { tokens } from "../theme";
import { Link } from "react-router-dom";

const Numbers = (props) => {
  const Agent = props.agent;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let SalesID = "Undefined";
  if (Agent) {
    SalesID = Agent.SalesID;
  }

  return (
    <Box
      sx={{ gridColumn: "span 2" }}
      display={"grid"}
      m="20px"
      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
    >
      <Box sx={{ gridColumn: "span 4", fontWeight: "Bold" }}>
        <Header title="Numbers" subtitle={"Spectrum Sales ID: " + SalesID} />
      </Box>
      <Box
        className="mb-2"
        alignContent={"center"}
        sx={{ gridColumn: "span 4" }}
      >
        <Typography color={colors.greenAccent[500]} variant="h5">
          Dealer Support Phone Numbers
        </Typography>
      </Box>
      <Box sx={{ gridColumn: "span 1" }}>
        <p>Agent Support </p>
        <p>Core Services Dealer Support </p>
        <p>Mobile Services Dealer Support </p>
        <p>Serviceability Check </p>
      </Box>
      <Box sx={{ gridColumn: "span 1" }}>
        <Typography color={colors.greenAccent[500]} variant="p">
          <a className="block" href="tel:(855) 866-3574">
            (855) 866-3574
          </a>
        </Typography>
        <Typography color={colors.greenAccent[500]} variant="p">
          <a className="block" href="tel:(855) 392-9910">
            (855) 392-9910
          </a>
        </Typography>
        <Typography color={colors.greenAccent[500]} variant="p">
          <a className="block" href="tel:(833) 224-6603">
            (833) 224-6603
          </a>
        </Typography>
        <Typography color={colors.greenAccent[500]} variant="p">
          <a className="block" href="tel:(844) 610-5092">
            (844) 610-5092
          </a>
        </Typography>
      </Box>
      <Box className="my-2" sx={{ gridColumn: "span 4" }}>
        <Typography color={colors.greenAccent[500]} variant="h5">
          Customer Support Phone Numbers
        </Typography>
      </Box>
      <Box sx={{ gridColumn: "span 1" }}>
        <p>Customer Support</p>
        <p>Mobile Support</p>
        <p>Assurant Mobile Insurance</p>
        <p>Installation Support</p>
      </Box>
      <Box sx={{ gridColumn: "span 1" }}>
        <Typography color={colors.greenAccent[500]} variant="p">
          <a className="block" href="tel:(800) 892-4357">
            (800) 892-4357
          </a>
        </Typography>
        <Typography color={colors.greenAccent[500]} variant="p">
          <a className="block" href="tel:(833) 224-6603">
            (833) 224-6603
          </a>
        </Typography>
        <Typography color={colors.greenAccent[500]} variant="p">
          <a className="block" href="tel:(877) 875-4282">
            (877) 875-4282
          </a>
        </Typography>
        <Typography color={colors.greenAccent[500]} variant="p">
          <a className="block" href="tel:(877) 309-5869">
            (877) 309-5869
          </a>
        </Typography>
      </Box>
    </Box>
  );
};

export default Numbers;
