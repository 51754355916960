import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, TextField, Checkbox } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { AuthContextProvider, UserAuth } from "../../context/AuthContext";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const Iframe = (props) => {
  const [showAdTypeSection, setShowAdTypeSection] = useState(false);
  const [campaigns, setCampaigns] = useState([]); // Store fetched campaigns
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const Agent = props.agent;
  let Age = "";
  const notify = (x) => toast(x);
  const notifyError = (x) => toast.error(x);
  console.log(Agent);
  // const { user } = UserAuth();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    if (Agent && Agent.Status && Agent.Status !== "Active") {
      navigate("/");
    } else if (Agent && Agent.Status && Agent.Status == "Active") {
      fetchCampaignsbyPartnerID();
    }
  }, [Agent, navigate]);

  const fetchCampaignsbyPartnerID = async () => {
    let TempPid =
      Agent && Agent.Hierarchy === "Master" ? "VTL1297" : Agent.PartnerID;

    let url =
      "https://us-central1-argon-fx-383518.cloudfunctions.net/getCampaigns";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ PartnerID: TempPid }),
    })
      .then((response) => response.json())
      .then((Data) => {
        console.log("fetch: ", Data);
        setCampaigns(Data); // Store fetched campaigns
        setShowAdTypeSection(Data && Data.length > 0); // Only show ad section if campaigns exist
      })
      .catch((error) => {
        console.error("Error fetching campaigns:", error);
      });
  };

  const filterCampaignsByAdType = (adType) => {
    return campaigns
      .filter((campaign) => campaign.AdType === adType)
      .map((campaign) => (
        <MenuItem key={campaign.ID} value={campaign.AdID}>
          {campaign.AdID}
        </MenuItem>
      ));
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    if (values.campaign !== "") {
      // Get platform from the selected campaign
      const selectedCampaign = campaigns.find(
        (campaign) => campaign.AdID === values.campaign
      );
      if (selectedCampaign) {
        values.platform = selectedCampaign.Platform; // Add platform to the values
        values.partner = selectedCampaign.Partner;
      }
    }
    // Get logged in user data from props or database call
    let address1,
      address2,
      zipcode,
      fname,
      lname,
      phone,
      mobile,
      email,
      present,
      moving,
      affiliate,
      agentEmail,
      storeID,
      salesID,
      accountID,
      affiliateID,
      salesAgentID,
      transID = "";
    if (Agent != undefined) {
      console.log("hmm");
      // affiliateID = Agent.AffiliateID;
      affiliateID = "422494";
      agentEmail = Agent.Email;
      values.affiliateID = "422494";
      values.agentEmail = Agent.Email;
      values.agentID = Agent.AgentID;
      values.partnerID = Agent.PartnerID;
      if (values.moving == "true") {
        values.moving = true;
      } else {
        values.moving = false;
      }
    }

    try {
      const response = await fetch(
        "https://us-central1-argon-fx-383518.cloudfunctions.net/SalesOrderWrite", // Replace with your Cloud Function URL
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );
      console.log("inner: ", values);
      console.log("inner strngfy: ", JSON.stringify(values));

      if (response.ok) {
        // Form submitted successfully
        console.log("Form submitted successfully!");
        const payload = {
          zipCode: values.zipCode,
          values: values,
          URL: `https://retail.spectrum.com/address/agent-retail/localization?address1=${values.address1}&address2=${values.address2}&zip=${values.zipCode}&fname=${values.firstName}&lname=${values.lastName}&phone=${values.mobile}&mobile=${values.mobile}&email=${values.email}&affiliate=${affiliateID}&agentEmail=${agentEmail}&salesID=${Agent.AgentID}&present=${values.present}&moving=${values.moving},
            "_blank", "height=400"`,
        };
        navigate("/serviceproviders", { state: payload });
      } else {
        // Error submitting form
        console.error("Error submitting form");
        notifyError("Error: Error submitting form [LE005]");
      }
    } catch (error) {
      console.error(error);
      notifyError(`Error: Error submitting form [LE006]: ${error}`);
    } finally {
      setLoading(false); // Set loading to false when submission is complete
    }

    // const payload = {
    //   zipCode: values.zipCode,
    //   values: values,
    //   URL: `https://retail.spectrum.com/address/agent-retail/localization?address1=${values.address1}&address2=${values.address2}&zip=${values.zipCode}&fname=${values.firstName}&lname=${values.lastName}&phone=${values.mobile}&mobile=${values.mobile}&email=${values.email}&affiliate=${affiliateID}&agentEmail=${agentEmail}&salesID=${Agent.AgentID}&present=${values.present}&moving=${values.moving},
    //     "_blank", "height=400"`,
    // };
    // navigate("/serviceproviders", { state: payload });
  };

  const handleFormSubmit = (values) => {
    console.log(values);
  };

  return (
    <div className="m-5">
      <Header title="NEW ORDER" subtitle="Place order" />
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validateOnBlur={true}
        validationSchema={yup.object().shape({
          firstName: yup.string().required("required"),
          lastName: yup.string().required("required"),
          email: yup.string().email("invalid email").required("required"),
          mobile: yup
            .string()
            .matches(phoneRegExp, "Phone number is not valid")
            .test("len", "Must be exactly 10 characters", (val) => {
              if (val) return val.toString().length === 10;
            })
            .required("required"),
          address1: yup.string().required("required"),
          zipCode: yup
            .string()
            .test("len", "Must be exactly 5 characters", (val) => {
              if (val) return val.toString().length === 5;
            })
            .required("required"),
          moving: yup.string().required("A radio option is required"),

          // Conditional validation for adType based on whether the section is shown
          adType: yup.string().when([], {
            is: () => showAdTypeSection, // When showAdTypeSection is true, require adType
            then: yup.string().required("You must select an Ad Type"),
            otherwise: yup.string().notRequired(),
          }),

          // Conditional validation for campaign based on whether adType is selected
          campaign: yup.string().when("adType", {
            is: (adType) => adType && adType !== "", // if adType is selected
            then: yup.string().required("You must select a campaign"),
            otherwise: yup.string().notRequired(),
          }),
        })}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              {showAdTypeSection && (
                <>
                  <Box sx={{ gridColumn: "span 2" }}>
                    <FormLabel id="adType-label">Ad Type</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="adType"
                      name="adType"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.adType}
                      error={!!touched.adType && !!errors.adType}
                    >
                      <FormControlLabel
                        value="Messenger"
                        control={<Radio color="secondary" />}
                        label="Messenger"
                      />
                      <FormControlLabel
                        value="Incoming Call"
                        control={<Radio color="secondary" />}
                        label="Incoming call"
                      />
                      <FormControlLabel
                        value="Form"
                        control={<Radio color="secondary" />}
                        label="Form"
                      />
                    </RadioGroup>
                    {touched.adType && errors.adType ? (
                      <div style={{ color: "red" }}>{errors.adType}</div>
                    ) : null}
                  </Box>

                  <Box
                    sx={{
                      gridColumn: "span 1",
                      display: "flex",
                      alignItems: "center",
                      marginTop: "15px",
                    }}
                  >
                    <Select
                      labelId="campaign-select-label"
                      id="campaign-select"
                      sx={{ height: "35px" }}
                      value={values.campaign || ""}
                      onChange={handleChange}
                      name="campaign"
                      displayEmpty
                      error={!!touched.campaign && !!errors.campaign}
                    >
                      <MenuItem value="" disabled>
                        <em>Select a campaign</em>
                      </MenuItem>

                      {/* Dynamically render options based on selected AdType */}
                      {values.adType && filterCampaignsByAdType(values.adType)}
                    </Select>
                    {touched.campaign && errors.campaign && (
                      <div style={{ color: "red" }}>{errors.campaign}</div>
                    )}
                  </Box>
                </>
              )}

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="First Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                name="firstName"
                error={!!touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Last Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                name="lastName"
                error={!!touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Mobile Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.mobile}
                name="mobile"
                error={!!touched.mobile && !!errors.mobile}
                helperText={touched.mobile && errors.mobile}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Address 1"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address1}
                name="address1"
                error={!!touched.address1 && !!errors.address1}
                helperText={touched.address1 && errors.address1}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Apt / Suite / Lot"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address2}
                name="address2"
                error={!!touched.address2 && !!errors.address2}
                helperText={touched.address2 && errors.address2}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Zip code"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.zipCode}
                name="zipCode"
                error={!!touched.zipCode && !!errors.zipCode}
                helperText={touched.zipCode && errors.zipCode}
                sx={{ gridColumn: "span 1" }}
              />
              <Box sx={{ gridColumn: "span 1" }}>
                <FormLabel id="moving-label">Customer moving ?</FormLabel>
                <RadioGroup
                  row
                  id="moving"
                  aria-labelledby="moving-label"
                  name="moving"
                  label="moving"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.moving}
                  // defaultChecked={false}
                  error={!!touched.moving && !!errors.moving}
                  helperText={touched.moving && errors.moving}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio color="secondary" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio color="secondary" />}
                    label="No"
                  />
                </RadioGroup>
              </Box>
              <Box sx={{ gridColumn: "span 1" }}>
                <FormLabel id="present-label">Customer present ?</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="present-label"
                  name="present"
                  label="present"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.present}
                  error={!!touched.present && !!errors.present}
                  helperText={touched.present && errors.present}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio color="secondary" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio color="secondary" />}
                    label="No"
                  />
                </RadioGroup>
              </Box>
            </Box>
            {values.moving == "true" ? (
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              >
                {" "}
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Previous Address 1"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.prevaddress1}
                  name="prevaddress1"
                  error={!!touched.prevaddress1 && !!errors.prevaddress1}
                  helperText={touched.prevaddress1 && errors.prevaddress1}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Previous Apt / Suite / Lot"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.prevaddress2}
                  name="prevaddress2"
                  error={!!touched.prevaddress2 && !!errors.prevaddress2}
                  helperText={touched.prevaddress2 && errors.prevaddress2}
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Previous Zip code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.prevzipCode}
                  name="zipCode"
                  error={!!touched.prevzipCode && !!errors.prevzipCode}
                  helperText={touched.prevzipCode && errors.prevzipCode}
                  sx={{ gridColumn: "span 1" }}
                />
              </Box>
            ) : (
              ""
            )}
            <Box display="flex" justifyContent="center" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={loading} // Disable the button when loading is true
              >
                {loading ? "Placing order..." : "Place order"}{" "}
                {/* Change button text */}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </div>
  );
};

// const phoneRegExp =
//   /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
const phoneRegExp =
  /^(?:(?:\+?[1-9]{1,4}[ -]?)|(?:\([0-9]{2,3}\)[ -]?)|(?:[0-9]{2,4}[ -]?))*[0-9]{10}$/;

const checkoutSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  mobile: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .test("len", "Must be exactly 10 characters", (val) => {
      if (val) return val.toString().length === 10;
    })
    .required("required"),
  address1: yup.string().required("required"),
  zipCode: yup
    .string()
    .test("len", "Must be exactly 5 characters", (val) => {
      if (val) return val.toString().length === 5;
    })
    .required("required"),
  moving: yup.string().required("A radio option is required"),

  // Conditional validation for adType based on whether the section is shown
  adType: yup.string().when("$showAdTypeSection", {
    is: true, // when showAdTypeSection is true (section is displayed)
    then: yup.string().required("You must select an Ad Type"),
    otherwise: yup.string().notRequired(),
  }),

  // Conditional validation for campaign based on whether adType is selected
  campaign: yup.string().when("adType", {
    is: (adType) => adType && adType !== "", // if adType is selected
    then: yup.string().required("You must select a campaign"),
    otherwise: yup.string().notRequired(),
  }),
});

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  address1: "",
  address2: "",
  zipCode: "",
  present: true,
  adType: "",
  campaign: "",
};

export default Iframe;
