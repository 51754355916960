import React from "react";
import Header from "../../components/Header";
import { Box, Button, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { tokens } from "../../theme";
import { Link } from "react-router-dom";

const Portals = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const Agent = props.agent;
  if (Agent) {
    return (
      <Box
        m="20px"
        display={"grid"}
        columnGap={"30px"}
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
      >
        {/* TOP STUFF */}
        <Box
          sx={{ gridColumn: "span 4" }}
          display={"grid"}
          gridTemplateColumns="1fr"
          rowGap={"15px"}
          textAlign={"left"}
          justifyItems={"left"}
          marginLeft={"25px"}
          marginBottom={"50px"}
        >
          <Box sx={{ gridColumn: "span 1" }}>
            <img
              style={{ height: "50px" }}
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/Charter_Spectrum_logo.svg/800px-Charter_Spectrum_logo.svg.png"
            />
          </Box>
          <Button
            sx={{ width: "205px" }}
            variant="contained"
            color="secondary"
            component={Link}
            to="/"
            disabled
          >
            Comissions Report
          </Button>
        </Box>

        {/* LEFT STUFF */}
        <Box
          sx={{ gridColumn: "span 1" }}
          display={"grid"}
          gridTemplateColumns="1fr"
          rowGap={"15px"}
          textAlign={"center"}
          justifyItems={"center"}
        >
          <Box sx={{ gridColumn: "span 1" }}>
            <Typography color={colors.grey[100]} variant="h4" fontWeight="600">
              Core Reports
            </Typography>
          </Box>
          <Button
            sx={{ width: "205px" }}
            variant="contained"
            color="secondary"
            component={Link}
            to="/spectrumCREnteredOrders"
          >
            Entered Orders
          </Button>
          <Button
            sx={{ width: "205px" }}
            variant="contained"
            color="secondary"
            component={Link}
            to="/SpectrumConnectedOrders"
          >
            Connected Orders
          </Button>
          <Button
            sx={{ width: "205px" }}
            variant="contained"
            color="secondary"
            component={Link}
            to="/SpectrumCanceledOrders"
          >
            Canceled Orders
          </Button>
          <Button
            sx={{ width: "205px" }}
            variant="contained"
            color="secondary"
            component={Link}
            to="/SpectrumPendingOrders"
          >
            Pending Orders
          </Button>
          <Button
            sx={{ width: "205px" }}
            variant="contained"
            color="secondary"
            component={Link}
            to="/SpectrumChargebacks"
          >
            Chargebacks
          </Button>
        </Box>

        {/* RIGHT STUFF */}
        <Box
          sx={{ gridColumn: "span 1" }}
          display={"grid"}
          gridTemplateColumns="1fr"
          rowGap={"15px"}
          textAlign={"center"}
          justifyItems={"center"}
          height={"fit-content"}
        >
          <Box sx={{ gridColumn: "span 1" }}>
            <Typography color={colors.grey[100]} variant="h4" fontWeight="600">
              Mobile Reports
            </Typography>
          </Box>
          <Button
            sx={{ width: "205px" }}
            variant="contained"
            color="secondary"
            component={Link}
            to="/"
            disabled
          >
            Entered Orders
          </Button>
          <Button
            sx={{ width: "205px" }}
            variant="contained"
            color="secondary"
            component={Link}
            to="/"
            disabled
          >
            On Network Activations
          </Button>
          <Button
            sx={{ width: "205px" }}
            variant="contained"
            color="secondary"
            component={Link}
            to="/"
            disabled
          >
            Pending Activations
          </Button>
        </Box>
      </Box>
    );
  }
};

export default Portals;
