import React, { useEffect } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import Iframe from "react-iframe";

const CallFlow = () => {
  useEffect(() => {}, []);
  return (
    <Box mt={"13px"}>
      <iframe
        src="https://docs.google.com/presentation/d/e/2PACX-1vSjhVrVzY61sjcDK-blIa1B0eG1hk70PfC-dOQziDqS3Ly65_95LgAt7ebsvFPYyaw7dxLvgrJrLQIY/embed?start=false&loop=false&delayms=3000"
        frameborder="0"
        width="1280px"
        height="749px"
        allowfullscreen="true"
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
      ></iframe>
    </Box>
  );
};

export default CallFlow;
