import { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import { tokens } from "../../theme";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";

import { mockPieData as data } from "../../data/mockData";

const Circle = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const Agent = props.agent;
  const Data = props.data;
  const Period = props.period;
  const [cancellations, setCancellations] = useState();
  const [all, setAll] = useState();
  const [percentage, setPercentage] = useState(0);
  const [colorScale, setColorScale] = useState("#4cceac");

  useEffect(() => {
    if (Agent && Agent.PartnerID) {
      dataChurningMachine();
    }
  }, [Period, Data, colorScale, percentage]);

  const dataChurningMachine = () => {
    let Cancellations = 0;
    let All = 0;
    Data.forEach((item) => {
      let fiscalCaps = item.FiscalMonth.toUpperCase();
      let periodCaps = Period.toUpperCase();
      if (
        Period == "all" ||
        (fiscalCaps.search(periodCaps) >= 0 && Period != "")
      ) {
        if (item.SalesConnects == 1) {
          All++;
        }
        if (item.SalesCanceled == 1) {
          Cancellations++;
        }
      }
    });

    setCancellations(Cancellations);
    setAll(All);
    if (((Cancellations / All) * 100).toFixed(2) >= 0) {
      setPercentage(((Cancellations / All) * 100).toFixed(2));
    }
    if (percentage > 10) {
      setColorScale("#e22839");
    }
  };

  return (
    <Box
      gridColumn="span 1"
      width="100%"
      // border="2px solid red"
      display="grid"
      gridTemplateColumns="repeat(2, 1fr)"
      padding="5px"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        backgroundColor={colorScale}
        height="85px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        gridColumn="span 2"
        textAlign="center"
        borderRadius="25px"
        color="white"
      >
        <Typography variant="h5" fontWeight="600">
          Cancellations: {percentage}%
        </Typography>
      </Box>
    </Box>
  );
};

export default Circle;
