import React, { useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

const Sales101Quiz = (props) => {
  const Agent = props.agent;

  // Debug output to ensure Agent is being passed correctly
  useEffect(() => {
    console.log("Agent data:", Agent);
  }, [Agent]);

  return (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {Agent && Agent.FirstName && Agent.LastName && Agent.Email ? (
        <iframe
          src={`https://www.classmarker.com/online-test/start/?quiz=6kf659c9c0dc3761&cm_user_id=${Agent.AgentID}&cm_fn=${Agent.FirstName}&cm_ln=${Agent.LastName}&cm_e=${Agent.Email}`}
          data-quiz="6kf659c9c0dc3761"
          width="700px"
          height="800px"
          title="ClassMarker Quiz"
        ></iframe>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center">
          {/* Change spinner color using sx prop */}
          <CircularProgress sx={{ color: "red" }} />{" "}
          {/* Change to blue if you prefer */}
          <Typography variant="body1" mt={2} color="black">
            Loading Agent details...
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Sales101Quiz;
